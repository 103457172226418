@import '../../../styles/colors';
@import '../../../styles/metrics';
@import '../../../styles/mixins';

.sidebarWrapper {
  padding: 10px;
  position: sticky;
  top: 0;

  input {
    width: 100%;
  }
}

// sass-lint:disable nesting-depth
.flightDetailsNavigation {
  ol {
    display: flex;
    flex-direction: column;
    gap: 10px;
    list-style-type: none;

    li {
      width: 100%;
      word-wrap: normal;

      button {
        align-items: center;
        background: transparent;
        display: flex;
        flex-direction: row;
        gap: 10px;
        justify-content: flex-start;

        padding: 0 var(--chakra-space-2);
        width: 100%;

        &.selected {
          background: $white;

          .bullet {
            circle {
              fill: $mustard;
              stroke: $mustard;
            }
          }
        }

        .sectionName {
          font-weight: 700;
          overflow: hidden;
        }
      }
    }
  }
}
