@import '../../../styles/colors';
@import '../../../styles/metrics';
@import '../../../styles/mixins';

.notificationListItemContent {
  align-items: center;
  flex-direction: row;
  height: 26px;
}

.notificationAirlineLogo {
  margin-right: 0;
}

.notificationListItem {
  cursor: pointer;
  flex-direction: column;
  justify-content: space-between;
  padding: 16px 32px;

  &:hover {
    background-color: $lightGrayBlue;
  }

  &:not(:last-of-type) {
    border-bottom: 1px solid $lightGrayBlue2;
  }

  &:last-of-type {
    border-bottom-left-radius: var(--chakra-radii-md);
    border-bottom-right-radius: var(--chakra-radii-md);
  }
}

.emptyNotification:hover {
  background-color: $white;
  cursor: default;
}

.popoverBodyNotifications {
  align-items: stretch;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  overflow-y: scroll;
  padding: 0 !important;
}

.popoverHeaderNotifications {
  font-family: Lato;
  font-size: 16px;
  font-weight: 700;
  margin: 16px;
  padding: 0 !important;
}

.popoverNotifications {
  max-height: 450px;
  min-width: 560px;
  position: relative;
  right: 12px;
}

.notificationUserAvatar {
  border: 1px solid $grayscaleBlack;
  border-radius: 50%;
  display: block;
  margin-top: 3px;
}

.notificationUserAvatarInitials {
  font-family: Lato;
  font-size: 11px;
  font-weight: 600;
  letter-spacing: 0.1em;
  line-height: 29.5px;
  text-align: center;
}

.systemUserAvatar {
  font-size: 10px;
  letter-spacing: 0;
}

.messagePreview {
  align-self: center;
  font-family: Lato;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 18px;
  margin-bottom: 8px;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 410px;
}

.messageTimestamp {
  align-self: center;
  color: $carbonoLighterGray;
  font-family: Lato;
  font-size: 11px;
  font-weight: 600;
  letter-spacing: 0.1em;
  line-height: 14px;
}

.unreadMessagesCount {
  color: $mustard500;
  font-family: Lato;
  font-size: 11px;
  font-weight: 600;
  letter-spacing: 0.1em;
  line-height: 14px;
  margin-top: 0 !important;
  text-align: left;
  text-transform: uppercase;
}
