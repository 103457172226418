@import '../../../styles/colors';
@import '../../../styles/metrics';
@import '../../../styles/mixins';

.dateRangePickerWrapper {
  align-items: center;
  border: 1px solid var(--chakra-colors-blackAlpha-300) !important;
  border-radius: 0.313rem;
  display: flex;
  height: 2.5rem;
  justify-content: center;
  padding-left: 1.75rem;
  padding-right: 0.1rem;
}

.field {
  text-align: center;
  width: min-content;
}

.field:disabled {
  background-color: hsl(0, 0%, 95%);
  border-color: hsl(0, 0%, 90%);
}

// sass-lint:disable no-vendor-prefixes
.inputDate {
  appearance: none;
  background: inherit;
  border-color: transparent;
  font-size: var(--chakra-fontSizes-md);
  height: var(--chakra-sizes-10);
  min-width: 0;
  outline: 2px solid transparent;
  outline-offset: 2px;
  padding: 0;
  position: relative;
  transition-duration: var(--chakra-transition-duration-normal);
  transition-property: var(--chakra-transition-property-common);
  width: 9.5rem;

  .date {
    border: 0;
    font-size: 16px;
    line-height: var(--chakra-sizes-10);
  }

  &:has(input[disabled]) {
    background-color: $disabledDatepicker;
    opacity: 0.7 !important;
  }
}

input.errors,
div.errors {
  border-color: $error;
  color: $error;

  * {
    border-color: $error;
  }
}

small.errors {
  color: $error;
}
