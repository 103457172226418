@import '../../../styles/colors';
@import '../../../styles/metrics';
@import '../../../styles/mixins';

.destinationOrigin {
  align-items: center;
  height: 100%;
  overflow: hidden;
  padding-right: 0.5vw;
  white-space: nowrap;
  width: 100%;
}

.fidsDestinationRotating,
.fidsDestinationRotating * {
  height: 100%;
}

.via {
  font-style: italic;
}

.departure,
.arrival {
  color: $defaultFidsBlue;
}
