@import '../../../styles/colors';
@import '../../../styles/metrics';
@import '../../../styles/mixins';

$tableHeader: $lightGrayBlue;

.listWrapper {
  max-height: 100%;
  overflow-y: auto;
  width: 100%;
}

// sass-lint:disable no-vendor-prefixes
::-webkit-scrollbar {
  background: transparent;
  width: 0;
}

table {
  font-family: Lato;
  height: fit-content;
  text-align: center;
  width: 100%;
}

.tableHeader {
  background-color: $tableHeader;
  width: 100%;
}

.tableHeader > tr > th {
  background-color: $tableHeader;
  font-family: Lato;
  font-size: 13px;
  font-style: normal;
  font-weight: bold;
  line-height: 14px;
  padding: 12px 0;
  position: sticky;
  text-transform: uppercase;
  top: 0;
  z-index: 1;
}
