@import '../../../styles/colors';
@import '../../../styles/metrics';
@import '../../../styles/mixins';

.selectOptions {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  overflow: hidden;
}

.selectOptions.disabled {
  opacity: 40%;
}

.labelComponent {
  overflow: hidden;

  * {
    align-items: center;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

.simpleSelectOption {
  color: $darkGray;
  font-size: 16px;
  font-weight: normal;
  line-height: 19px;
}

.simplePrioritySelectOption {
  align-items: center;
  color: $darkGray;
  display: flex;
  font-size: 16px;
  font-weight: normal;
  line-height: 19px;

  span {
    border-radius: 50%;
    display: inline-flex;
    height: 12px;
    margin-right: 8px;
    width: 12px;
  }

  span.low {
    background-color: $lowAlertColor;
  }

  span.medium {
    background-color: $mediumAlertColor;
  }

  span.high {
    background-color: $highAlertColor;
  }
}

.simpleDescSelectOption {
  color: $middleGray;
  display: inline-block;
  font-size: 14px;
  line-height: 17px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.codeSelectOptions {
  color: $darkGray;
  font-family: Lato;
  font-size: 16px;
  font-style: normal;
  font-weight: bold;
  line-height: 19px;
  margin-right: 4px;
}

.descSelectOptions {
  color: $middleGray;
  display: inline-block;
  font-family: Lato;
  font-size: 14px;
  font-style: normal;
  font-weight: normal;
  line-height: 17px;
  margin-right: 4px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.logoContainer {
  align-items: center;
  border: 1px solid $lightGray;
  border-radius: 4px;
  box-sizing: border-box;
  display: flex;
  height: 26px;
  justify-content: center;
  margin-right: 8px;
  position: static;
  width: 70px;
}

.formControl:not(:last-child) {
  margin-right: 1rem;
}

.statusDescSelectOptions {
  color: $darkGray;
  display: flex;
  font-family: Lato;
  font-size: 16px;
  font-style: normal;
  font-weight: normal;
  line-height: 19px;
}

.buttonWrapper {
  align-self: flex-end;
  margin-bottom: 8px;

  button {
    height: 25px;
  }
}

input.errors,
div.errors {
  border-color: $error;
  color: $error;

  * {
    border-color: $error;
  }
}

small.errors {
  color: $error;
}

.noOptions {
  color: #808080;
  font-family: Lato;
  font-size: 16px;
  font-style: normal;
  font-weight: normal;
  line-height: 18px;
  margin-right: 4px;
  text-align: center;
}

.field:disabled {
  background-color: hsl(0, 0%, 95%);
  border-color: hsl(0, 0%, 90%);
}

.selectContainerWithOneButton {
  max-width: calc(100% - 40px);
}

.selectContainerWithBothButtons {
  max-width: calc(100% - 100px);
}

.overflowCounterLabel {
  color: $darkGray;
  font-size: 14px;
}

.selectTerminalLabel {
  align-items: center;
  background-color: $midToneGray;
  display: flex;
  justify-content: center;
  padding: 0.2rem 0;
  position: sticky;
  top: 0;
  width: 100%;

  span {
    color: $white;
    display: inline-block;
    font-family: 'Lato';
    font-size: 13px;
    font-weight: 400;
    overflow: visible;
  }
}
