@import '../../../styles/colors';
@import '../../../styles/metrics';
@import '../../../styles/mixins';

.movementsReadOnlyLabel {
  color: $darkGray;
  font-size: 15px;
  font-weight: bold;
}

.movementsReadOnlyText {
  color: $darkGray;
  font-size: 18px;
  margin-top: 6px;
}
