@import '../../../../../styles/colors';
@import '../../../../../styles/metrics';
@import '../../../../../styles/mixins';

.gateHeader,
.gateHeader > * {
  align-items: center;
  height: 15vh;
  overflow: hidden;
  width: 100%;
}

.gateHeader img {
  max-width: fit-content;
}

.gateFlightNumber {
  justify-content: flex-end;
}

.logo {
  background-color: inherit;
  border: 0;

  img {
    height: 100%;
    object-fit: scale-down;
    width: auto;
  }
}

.gateTime {
  justify-content: end;

  div {
    font-size: var(--resource-card-header-font-size);
    font-weight: bold;
  }

  svg path {
    stroke: $resourceCardHeaderTextColor;
  }
}

.gateBody {
  color: $defaultFidsDarkGray;
  height: 100%;
  justify-content: space-around;
  text-align: center;
  width: 100%;

  > * {
    height: initial;
  }
}
