@import '../../../styles/colors';
@import '../../../styles/metrics';
@import '../../../styles/mixins';

.flightStatusWrapper {
  align-self: center;
  border-radius: 4px;
  height: fit-content;
  overflow: visible;
  padding: 4px 8px;
  width: fit-content;
}

.flightStatusText {
  font-family: Lato;
  font-size: $smallFontSize;
  font-style: normal;
  font-weight: bold;
  line-height: 14px;
  text-align: center;
}
