@import '../../../styles/colors';
@import '../../../styles/metrics';
@import '../../../styles/mixins';

.logoutButton {
  align-items: center;
  background: $white;
  border: 1px solid $lighterGray;
  justify-content: center;
  margin-right: 8px;
  padding: 8px 16px;
}

.versionText {
  color: $darkGray;
  font-size: 14px;
  font-weight: 500;
}

.logoutText {
  color: $darkGray;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0.1em;
}
