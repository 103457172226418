@import '../../../styles/colors';
@import '../../../styles/metrics';
@import '../../../styles/mixins';

.flightEditWrapper {
  font-family: Lato;
}

.sectionForm {
  margin: 1.5rem 0;

  .sectionDivisor {
    margin-top: 2rem !important;
  }
}

.formControl:not(:last-child) {
  margin-right: 1rem;
}

input.errors,
div.errors {
  border-color: $error;
}

small.errors {
  color: $error;
}

.readonlyFieldLabel {
  color: $darkGray;
  display: block;
  font-size: var(--chakra-fontSizes-md);
  margin-bottom: var(--chakra-space-2);
  text-align: start;
}
