@import '../../../../styles/colors';
@import '../../../../styles/metrics';
@import '../../../../styles/mixins';

// sass-lint:disable nesting-depth
.chatThreadList {
  align-items: stretch;
  border: 1px solid $lighterGray;
  display: flex;
  flex-direction: column;
  height: 60vh;
  justify-content: flex-start;
  list-style-type: none;
  overflow-y: auto;
  width: 16rem;

  li {
    border-bottom: 1px solid $lighterGray;

    button {
      align-items: center;

      display: flex;

      flex-direction: row;
      height: 50px;
      justify-content: space-between;

      padding: 0.5rem 1rem;
      width: 100%;

      .threadDetails {
        align-items: flex-start;
        display: flex;
        flex-direction: column;

        span.lastMessageTimestamp,
        span.startChat {
          font-size: 12px;
          font-weight: 600;
          letter-spacing: 0.1em;
          line-height: 14px;
          text-transform: uppercase;
        }

        span.lastMessageTimestamp {
          color: $darkGray;
        }

        span.startChat {
          color: $mustard500;
        }

        .threadNameContainer {
          margin-bottom: 0.1rem;
        }

        .threadTag {
          margin-left: 2px;
          padding: 2px 5px;
        }
      }

      &.selected {
        background-color: $mustard50;

        strong {
          color: $mustard600;
        }
      }

      &:disabled {
        background: $lightGrayBlue;
        color: $carbonoGray;

        cursor: initial;
      }

      .notificationBadge {
        margin-left: 5px;
      }
    }
  }
}

.notificationBadge {
  background-color: $unreadMessagesCountYellowBadgeBackground;
  border-radius: 4px;
  color: $unreadMessagesCountYellowBadgeFont;
  font-family: Lato;
  font-size: 14px;
  font-weight: 600;
  height: 18px;
  letter-spacing: 0.1em;
  line-height: 14px;
  min-width: 17px;
  padding: 2px 4px;
  text-align: center;
}
