@import '../../../styles/colors';
@import '../../../styles/metrics';
@import '../../../styles/mixins';

.searchBarWrapper {
  align-items: center;
  background-color: $white;
  border: 1px solid var(--chakra-colors-blackAlpha-300);
  border-radius: 4px;
  display: flex;
  flex-direction: row;
  gap: 8px;
  padding: 4px 8px;
  width: fit-content;
}

.searchBarInput {
  border: 0;
}

.searchBarInput:focus {
  outline: none;
}
