@import '../../../styles/colors';
@import '../../../styles/metrics';
@import '../../../styles/mixins';

.leftSide {
  align-items: center;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  margin-left: 12px;
}

.rightSide {
  align-items: center;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-end;

  .rightSideControls {
    align-items: center;
    display: flex;
    justify-content: flex-end;

    .footerMainButton {
      width: 154px;
    }
  }

  .moreButton {
    align-items: center;
    display: flex;
    height: 36px !important;
    justify-content: center;
    min-width: unset !important;
    padding-left: 0 !important;
    padding-right: 0 !important;
    width: 28px !important;

    > span {
      align-items: center;
      display: flex;
      justify-content: center;
    }
  }

  .moreMenu {
    border-radius: 8px !important;
    overflow: hidden;
    padding: 0 !important;

    > button {
      padding: var(--chakra-space-3);
    }
  }

  .menuMoreDeleteItem {
    color: $darkGray;

    &:hover {
      background-color: $urgency !important;

      svg {
        path {
          stroke: $white;
        }
      }
      color: $white;
    }
  }
}

.changedDataText {
  color: $darkGray;
  font-family: Lato;
  font-size: 14px;
  font-style: normal;
  font-weight: normal;
  line-height: 15px;
}

.textItemsButtonMore {
  font-family: Lato;
  font-size: 16px;
  font-style: normal;
  font-weight: normal;
  line-height: 19px;
  padding-left: 10px;
}

.textButtonReturnFlightControl {
  color: $carbon;
  font-size: 14px;
  font-style: normal;
  font-weight: bold;
  line-height: 17px;
  padding-left: 10px;
}

.textButtonSubmitAndReturn {
  color: $white;
  font-size: 14px;
  font-weight: bold;
  line-height: 17px;
}
