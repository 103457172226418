@import '../../../styles/colors';
@import '../../../styles/metrics';
@import '../../../styles/mixins';

:root {
  @include rootProp(--fidsFooterCompactHeight, #{$fidsFooterCompactHeight});
}

$footerHeight: var(--fidsFooterCompactHeight);
$fontSize: calc(#{$footerHeight} * 0.5);
$padding: calc(((#{$footerHeight} - #{$fontSize}) / 2) - 0.5vh);

.footer {
  background-color: $white;
  bottom: 0;
  display: flex;
  flex-direction: row;
  font-family: Lato;
  font-size: $fontSize;
  height: $footerHeight;
  left: 0;
  padding: $padding;
  position: fixed;
  width: 100%;
  z-index: 10;
}

p {
  display: inline-block;
  white-space: nowrap;
}
