@import '../../../styles/colors';
@import '../../../styles/metrics';
@import '../../../styles/mixins';

.save {
  color: $white;
  font-weight: bold;
}

.keepEditing {
  color: $carbon;
}

.save,
.keepEditing {
  margin-right: 10px;
}

.error {
  font-family: var(--chakra-fonts-body);
  font-weight: var(--chakra-fontWeights-semibold);
  white-space: pre-line;
}

.listOfConditions {
  margin-bottom: 1rem;
  margin-top: 1rem;
}
