@import '../../../styles/colors';
@import '../../../styles/metrics';
@import '../../../styles/mixins';

.towingDetailsScreen {
  flex-direction: row;
  height: calc(100% - 48px);
  justify-content: flex-start;
  overflow-y: auto;
  width: 100%;
}

.towingDetailsScreenContainer {
  height: fit-content;
  width: 100%;
}

.towingDetailsContainer {
  flex-direction: column;
  width: 100%;
}

.towingSection {
  background: $white;
  border-radius: 8px;
  flex-direction: column;
  margin: 10px;
  padding: 16px 24px;
  width: 100%;
}

.towingTitle {
  font-size: 24px;
  font-weight: bold;
  line-height: 29px;
  margin-bottom: var(--chakra-space-4);
}

.towingDetailsHeader {
  flex: 1;
  flex-direction: row;
  justify-content: space-between;
  margin-top: var(--chakra-space-2);
}

.towingAirlineLogo {
  height: 30px;
}

.arrivalFlight {
  color: $darkGray;
  font-size: 22px;
  font-weight: bold;
  line-height: 29px;
  padding-right: 5px;
}

.aircraft,
.totalTimeValue,
.totalTimeLabel,
.addTowingTitle,
.towingHistoryTitle {
  color: $darkGray;
  font-size: 18px;
  line-height: 22px;
}

.totalTimeLabel,
.addTowingTitle,
.towingHistoryTitle {
  font-weight: bold;
}

.addTowingMovementForm {
  width: 100%;
}

.modalButtonGroup {
  align-content: space-between;
  justify-content: flex-end;
  margin-bottom: var(--chakra-space-5) !important;
  margin-top: var(--chakra-space-8) !important;
  width: 100%;

  button {
    justify-content: space-between;
    width: 150px;
  }
}

.towingHistoryTitle {
  margin: var(--chakra-space-6) 0;
}
