@import '../../../styles/colors';
@import '../../../styles/metrics';
@import '../../../styles/mixins';

.paginator {
  display: flex;
  flex-direction: row;
  gap: 0.4rem;

  li {
    list-style-type: none;
    width: 3rem;

    button {
      border-radius: 4px !important;
      max-width: 3rem !important;
      transition: none !important;
    }
  }

  .prevPage {
    svg {
      transform: rotate(90deg);
    }
  }

  .nextPage {
    svg {
      transform: rotate(-90deg);
    }
  }

  .nextPage,
  .prevPage {
    svg {
      position: absolute;

      path {
        stroke: $carbon;
      }
    }
  }
}
