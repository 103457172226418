@import '../../../../../styles/colors';
@import '../../../../../styles/metrics';
@import '../../../../../styles/mixins';

:root {
  @include rootProp(--fidsNavbarHeight, #{$fidsNavbarHeight});
}

$navbarHeight: var(--fidsNavbarHeight);

.maintenance-content {
  align-items: center;
  display: flex;
  height: calc(100vh - var(--fidsNavbarHeight) - var(--fidsFooterHeight));
  justify-content: center;
  margin-bottom: var(--fidsFooterHeight);
  margin-top: calc(20px + var(--fidsNavbarHeight));
  width: 100%;

  svg {
    height: calc(2vw + 16px);
    margin-bottom: calc(2vw + 16px);
    width: calc(2vw + 16px);
  }
}

.maintenance-card {
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  height: fit-content;
  max-width: 80vw;
  padding: calc(2vw + 16px);
  text-align: center;
}

.maintenance-card-title {
  align-self: stretch;
  font-size: calc(1.5vw + 1em);
  font-style: normal;
  font-weight: 600;
  line-height: 1.2;
  margin-bottom: calc(1vw + 8px);
  text-align: left;
  width: 100%;
}

.maintenance-card-description {
  font-size: calc(1vw + 0.5em);
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  white-space: normal;
  word-wrap: break-word;
}

.maintenance-qr-code {
  display: flex;
  justify-content: center;
  margin-top: calc(1.5vw + 12px);
}

@media (orientation: portrait) {
  .maintenance-card-title {
    font-size: calc(3vw + 1em);
  }

  .maintenance-card-description {
    font-size: calc(2vw + 0.5em);
  }

  .maintenance-card {
    padding: calc(4vw + 16px);
  }
}

@media (orientation: landscape) {
  .maintenance-card-title {
    font-size: calc(1.5vw + 1em);
  }

  .maintenance-card-description {
    font-size: calc(1vw + 0.5em);
  }

  .maintenance-card {
    padding: calc(2vw + 16px);
  }
}
