@import '../../../styles/colors';
@import '../../../styles/metrics';
@import '../../../styles/mixins';

// sass-lint:disable nesting-depth

.statusSelect {
  align-items: stretch;
  display: flex;
  flex-direction: row;
  height: var(--chakra-sizes-10);
  justify-content: stretch;
  width: 225px;
  z-index: 60;

  & > * {
    width: 100%;
  }
}

.ganttHeader {
  background-color: $white;
  padding-bottom: 0.5rem;
  position: sticky;
  top: 0;
  z-index: 10;
}

.ganttDateControl {
  display: flex;
  justify-content: space-evenly;
  max-width: 53px;
  min-width: 53px;
  width: 100%;

  button {
    background-color: $white;
    border: 1px solid $ganttDateControlGray;
    border-bottom: 3px solid $ganttDateControlGray;
    border-radius: 0.125rem;
  }
}

.ganttTimeScaleHour {
  align-items: center;
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  margin-left: $ganttGapBetweenColumns;
  position: relative;

  > * {
    font-family: 'Lato';
    font-size: 13px;
    font-weight: 400;
    line-height: 15px;
    white-space: nowrap;
  }

  .ganttTimeScaleHourLabel {
    align-items: center;
    background-color: $midToneGray;
    border-radius: 3px;
    color: $white;
    display: flex;
    height: 1.25rem;
    justify-content: center;
    width: 100%;
  }

  .ganttTimeScaleHourDivision {
    display: flex;
    justify-content: space-evenly;
    min-width: $ganttColumnMinWidth;
    padding-top: 0.5rem;
    width: 100%;

    span:not(.hourDivider) {
      border-radius: 3px;
      display: flex;
      font-size: 12px;
      justify-content: center;
      padding: 4px;
      text-align: center;
      width: 20%;
    }

    .hourDivider {
      align-items: center;
      display: flex;
      justify-content: center;
      width: 5%;
    }

    .activeTimeBlock {
      background-color: $ganttGreen;
      color: $white;
    }

    .activeTimeBlockLine {
      border: 1px dashed #f00;
      box-shadow: 0 -1px 0 0 rgba(0, 0, 0, 0.1);
      content: '';
      display: flex;
      height: calc(100vh - 50px - 48px - 13.5rem);
      position: absolute;
      transform: translateY(1.5rem);
      z-index: 3;
    }
  }
}

.ganttAircraftTerminal {
  margin: 0.25rem 0;
  width: 100%;
}

.ganttAircraftTerminalLabel {
  align-items: center;
  background-color: $midToneGray;
  border-radius: 3px;
  display: flex;
  height: 100%;
  justify-content: center;
  min-width: 1.625rem;
  padding: 10px 0.25rem;
  position: relative;
  width: 1.625rem;

  span {
    color: $white;
    display: inline-block;
    font-family: 'Lato';
    font-size: 13px;
    font-weight: 400;
    line-height: 15px;
    min-height: 1.25rem;
    overflow: visible;
    text-align: center;
    transform: rotate(180deg);
    white-space: nowrap;
    writing-mode: vertical-rl;
  }
}

.ganttEmptyLabel {
  margin: 2px;
  max-width: 1.625rem;
  min-width: 1.625rem;
  padding: 10px 0.25rem;
  width: 1.625rem;
}

.ganttStandsWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
}

.ganttStand {
  margin: 0.25rem 0.5rem;
  min-height: 2rem;
}

.expandStandLineButton {
  align-items: center;
  height: 2rem;
  margin-right: 8px;
}

.expanded {
  transform: rotate(90deg);
}

.ganttStandLabel {
  font-family: 'Lato';
  font-size: 13px;
  height: 2rem;
  justify-content: space-between;

  .ganttStandTypeLabel {
    color: $white;
    cursor: default;
    font-size: 13px;
    font-weight: 800;
    line-height: 15px;
    margin-right: 0.5rem;
    text-transform: uppercase;
  }

  .ganttStandPositionLabel {
    color: $white;
    cursor: default;
    font-size: 13px;
    font-weight: 400;
    line-height: 15px;
    overflow: hidden;
    text-overflow: clip;
  }
}

.ganttStandTimeline {
  position: relative;
  width: 100%;
}

.expandableLineSegments {
  gap: 30px;
  margin-bottom: 32px;
}

.ganttLineSegment {
  border-left: 1px solid $ganttLineBackground;
  border-radius: 2.5px;
  border-right: 1px solid $ganttLineBackground;
  display: flex;
  height: 2rem;
  justify-content: center;
  overflow-x: hidden;
  position: absolute;

  &:hover {
    cursor: pointer;
  }
}

.ganttLineSegmentContent {
  align-items: center;
  color: $white;
  font-size: 13px;
  gap: 11px;
  justify-content: center;
  line-height: 15px;
  pointer-events: none;
  text-transform: uppercase;
}

.ganttLineDelayedSegment {
  justify-content: flex-start;
  padding: 0 0.5rem;
}

// sass-lint:disable function-name-format
// sass-lint:disable indentation
:root {
  @include rootProp(
    --zigZagEntry,
    conic-gradient(from 45deg at left, #0000, #000 1deg 89deg, #0000 90deg) 50%/100% 10px
  );
  @include rootProp(
    --zigZagExit,
    conic-gradient(from -135deg at right, #0000, #000 1deg 89deg, #0000 90deg) 50%/100% 10px
  );
  @include rootProp(
    --zigZagEntryExitLeft,
    conic-gradient(from 45deg at left, #0000, #000 1deg 89deg, #0000 90deg) left/51% 10px repeat-y
  );
  @include rootProp(
    --zigZagEntryExitRight,
    conic-gradient(from -135deg at right, #0000, #000 1deg 89deg, #0000 90deg) right/51% 10px
      repeat-y
  );
}

// sass-lint:disable no-vendor-prefixes
.ganttLineSegmentsEntryZigzag {
  -webkit-mask: var(--zigZagEntry);
  mask: var(--zigZagEntry);
}

// sass-lint:disable no-vendor-prefixes
.ganttLineSegmentsExitZigzag {
  -webkit-mask: var(--zigZagExit);
  mask: var(--zigZagExit);
}

// sass-lint:disable no-vendor-prefixes
.ganttLineSegmentsEntryExitZigzag {
  -webkit-mask: var(--zigZagEntryExitLeft), var(--zigZagEntryExitRight);
  mask: var(--zigZagEntryExitLeft), var(--zigZagEntryExitRight);
}

.ganttTooltipAirlineLogo {
  border: 0;
  height: 20px;
  width: 53.8px;
}
