@import '../../../styles/colors';
@import '../../../styles/metrics';
@import '../../../styles/mixins';

.mainContent {
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: min-content min-content auto min-content;
  height: 100%;
  overflow: auto;
}

.dashboardPanel {
  background-color: $white;
  box-shadow: 0 4px 12px $lighterGray;
  box-shadow: 0 4px 12px $lighterGray;
  display: grid;
  gap: 1rem;
  grid-template-columns: minmax(40rem, 1fr);
  grid-template-rows: 2.5rem minmax(5.5rem, auto);

  justify-content: center;

  padding: 1.5rem 2rem;
  position: relative;

  transition: all 0.15s ease-in-out;

  .btnCollapse {
    align-items: center;
    background-color: $white;
    border-radius: 50%;
    bottom: -0.75rem;
    box-shadow: 0 4px 12px $lighterGray;
    display: flex;
    height: 1.5rem;
    justify-content: center;
    position: absolute;
    right: 1rem;
    width: 1.5rem;

    svg {
      transform: rotate(-180deg);
      transition: all 0.15s ease-in-out;
    }
  }

  .panelTop {
    align-items: center;
    display: flex;
    justify-content: space-between;
    width: 100%;

    h1 {
      font-size: 24px;
      font-weight: 700;
      line-height: 24px;
    }

    menu {
      align-items: center;
      display: flex;
      flex-direction: row;
      gap: 1rem;

      margin: 0;
      padding: 0;
      width: max-content;

      .datePicker {
        align-items: center;
        display: flex;
        flex-direction: row;
        gap: 0.5rem;

        .hDivider {
          background-color: $darkGray;
          height: 1.5px;
          width: 1.25rem;
        }
      }

      button {
        align-items: center;
        border: 1px solid $lighterGray;
        border-radius: 0.25rem;
        display: flex;
        flex-direction: row;
        gap: 16px;
        justify-content: center;
        min-width: fit-content;
        padding: 8px 12px;
      }
    }
  }

  .panelCards {
    align-items: flex-start;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 0.6rem;
    justify-content: flex-start;
    transition: all 0.15s ease-in-out;

    .countIssue {
      display: none;
    }

    .listLevel {
      align-items: center;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      gap: 0.6rem;
      justify-content: flex-start;
      transition: all 0.15s ease-in-out;
    }

    .flightType {
      align-items: flex-end;
      background-color: $flightBackgroundColor;
      border: 1px solid $flightTypeBorderColor;
      border-radius: 0.25rem;
      display: flex;
      flex: 0 0 17.125rem;
      flex-direction: row;
      flex-direction: flex-start;
      flex-wrap: wrap;
      gap: 0.5rem;
      margin: 0 0.5rem 0 0;
      max-width: 17.125rem;
      padding: 0.5rem;
      width: 17.125rem;
    }

    .issueCards {
      align-items: flex-end;
      border: 2px solid transparent;
      display: flex;
      flex: 1;
      flex-direction: row;
      flex-wrap: wrap;
      gap: 0.5rem;
      margin: 0.5rem 0;
      position: relative;
    }

    .divider {
      background-color: $dashboardDivider;
      height: 5.5rem;
      width: 1.5px;
    }

    .dashCard {
      background-color: $flightBackgroundColor;
      height: 5.5rem;
      text-align: left;
      width: 7.75rem;

      &:hover {
        background-color: $flightHoverBackgroundColor;
      }

      strong {
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
        line-height: 24px;
      }

      span {
        font-size: $mediumFontSize;
        font-weight: 500;
        line-height: $mediumLineHeight;
      }

      &[class~='high'] {
        strong {
          color: $highAlertColor;
        }
        background-color: $white;
      }

      &[class~='high']:hover {
        background-color: $issueHoverBackgroundColor;
      }

      &[class~='medium'] {
        strong {
          color: $mediumAlertColor;
        }
        background-color: $white;
      }

      &[class~='medium']:hover {
        background-color: $issueHoverBackgroundColor;
      }

      &[class~='low'] {
        strong {
          color: $lowAlertColor;
        }
        background-color: $white;
      }

      &[class~='low']:hover {
        background-color: $issueHoverBackgroundColor;
      }

      &[class~='toggled'] {
        strong,
        span {
          color: $white;
        }

        background-color: $mustard500;

        &:hover {
          background-color: $mustard600;
        }

        &[class~='high'] {
          background-color: $highAlertColor;

          &:hover {
            background-color: $hoverHighAlertColor;
          }
        }

        &[class~='medium'] {
          background-color: $mediumAlertColor;

          &:hover {
            background-color: $hoverMediumAlertColor;
          }
        }

        &[class~='low'] {
          background-color: $lowAlertColor;

          &:hover {
            background-color: $hoverLowAlertColor;
          }
        }
      }
    }

    &.twoLines {
      .flightType {
        flex: 1;
        flex-direction: column;
      }

      .issueCards {
        flex: 12;
      }
    }
  }

  &.collapse {
    grid-template-rows: auto;
    padding: 1rem 2rem;
    transition: all 0.15s ease-in-out;

    .btnCollapse {
      svg {
        transform: rotate(0deg);
        transition: all 0.15s ease-in-out;
      }
    }

    .panelCards {
      height: 2.6rem;
      overflow: hidden;

      .flightType {
        flex: auto;
        flex-direction: row;
        margin: 0;
        max-width: fit-content;
        width: auto;
      }

      .issueCards {
        padding-right: 3rem;
        position: relative;

        .skeletonCard {
          order: 1;
        }

        .toggled {
          order: 0;
        }
      }

      .countIssue {
        align-items: center;
        border-radius: 4px;
        cursor: pointer;
        display: flex;
        font-size: 14px;
        height: 1.5rem;
        line-height: 16px;
        padding: 0.5rem;
        position: absolute;
        right: 0;
        right: 0;
        top: 0;
        top: 0;
      }

      .dashCard {
        align-items: center;
        flex-direction: row;
        height: 1.5rem;
        justify-content: center;
        max-width: 7.5rem;
        padding: 0.5rem;
        width: auto;

        &:not([class~='toggled']) {
          background-color: $lightGrayBlue;
        }

        &[class~='toggled'] {
          max-width: 19rem;
        }

        button {
          display: flex;
          flex-direction: row;
        }

        strong {
          font-size: 14px;
          font-weight: bold;
          line-height: 16px;
        }

        span {
          font-size: 14px;
          line-height: 16px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
    }
  }
}

.settingsModal {
  .contentForm {
    margin-top: var(--chakra-space-4);
    max-height: 55vh;
    overflow-y: auto;

    > * {
      align-items: center;
      height: 45px;
      margin: 5px 0;
    }
  }

  > p.txtDescription {
    color: $charcoal;
    font-size: 1rem;
    line-height: 1.2rem;
  }

  .sectionDivisor {
    position: relative;
    width: 100%;

    .containerFooter {
      width: 100%;
    }
  }

  .saveButton {
    > p {
      margin-right: 10px;
    }
  }
}

@media only screen and (max-width: 945px) {
  .divider {
    display: none;
  }
}

.listIssueWrapper {
  max-height: calc(100% - 48px);
  overflow-y: auto;
  padding: 0 1rem;
  width: 100%;
}

.listIssueHeader {
  color: $middleDarkGray;
  font-size: 11px;
  font-style: normal;
  font-weight: 600;
  letter-spacing: 0.1em;
  line-height: 14px;
  text-transform: uppercase;
}

.textList {
  color: $darkGray;
  flex: none;
  flex-grow: 0;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 17px;
  order: 0;
}

.textScheduledType {
  color: #9c9c9c;
  flex: none;
  flex-grow: 0;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 17px;
  margin-left: 8px;
  margin-right: 8px;
  order: 0;
}

.issueHeaderList {
  align-items: center;
  border-radius: 8px 16px 16px 8px;
  box-shadow: 0 4px 12px $lighterGray;
  flex-direction: row;
  height: 40px;
  padding: 1.5rem 1rem;
}

.issueListItem {
  align-items: center;
  background: $white;
  border-radius: 8px 16px 16px 8px;
  box-shadow: 0 4px 12px $lighterGray;
  cursor: pointer;
  flex-direction: row;
  height: 56px;
  margin-bottom: 12px;

  .editFlight {
    opacity: 0;
    transition: all 0.3s ease-out;
    visibility: hidden;
  }
}

.issueListItem:hover {
  .editFlight {
    opacity: 1;
    transition: all 0.3s ease-in;
    visibility: visible;
  }
}

.issueListColumnContent {
  align-items: center;
  display: flex;
  flex-direction: row;
  height: 100%;
  justify-content: stretch;
}

.priorityAlert {
  border-radius: 8px 0 0 8px;
  box-shadow: 0 4px 12px $lighterGray;
  height: 100%;
  width: 100%;
}

.editFlight {
  padding: 10px;
}
