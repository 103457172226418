@import '../../../styles/colors';
@import '../../../styles/metrics';
@import '../../../styles/mixins';

.resourceContainer {
  background-color: $resourceScreenBackground;
  flex: 1;
  flex-direction: column;
  font-family: Lato;
  justify-content: center;
  padding: 8vh 5vw;
}

.resourceContainer > *:not(:first-child) {
  margin-top: 5vh;
}

@media only screen and (max-width: 960px) {
  .resourceContainer {
    padding: 2vh 1.25vw;
  }
}
