@import '../../../../../styles/colors';
@import '../../../../../styles/metrics';
@import '../../../../../styles/mixins';

.checkinLogoContainer {
  border: 0;
  overflow: hidden;
  width: 65vw;

  > * {
    border: 0;
  }
}

.logo {
  height: 100%;
  object-fit: scale-down;
  width: auto;
}

.body {
  align-items: center;
  color: $defaultFidsDarkGray;
  display: block;
  flex: 1;
  flex-direction: column;
  height: 100%;
  justify-content: space-around;
  position: relative;
  width: 100%;
}

.checkinType {
  font-weight: bold;
  max-width: 100%;
  text-align: center;
  white-space: break-spaces;
}
