@import '../../../styles/colors';
@import '../../../styles/metrics';
@import '../../../styles/mixins';

.timeZone {
  align-items: center;
  display: inline-flex;
  flex-direction: row;
  flex-wrap: nowrap;
  margin-left: 10px;
}

.timeZoneText {
  font-size: 14px;
  font-style: normal;
  font-weight: normal;
  line-height: 16px;
  margin-left: 10px;
  margin-right: 10px;
}
