@import '../../../styles/colors';
@import '../../../styles/metrics';
@import '../../../styles/mixins';

.rightSide {
  align-items: center;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-end;

  .rightSideControls {
    align-items: center;
    display: flex;
    justify-content: flex-end;
  }
}

@media screen and (max-width: 1120px) {
  .formHalf {
    min-width: fit-content;
  }
}
