@import '../../../styles/colors';
@import '../../../styles/metrics';
@import '../../../styles/mixins';

.listItemButtonWrapper,
.selectedListItemButtonWrapper {
  border-radius: 5px;
  padding: 3.5% 0 !important;

  &:hover {
    text-decoration: underline;
  }
}

.listItemButtonText,
.selectedListItemButtonText {
  align-self: center;
  color: $standardTextColor;
  font-size: 115%;
  line-height: 19px;
}

.listItemButtonWrapper {
  color: $standardTextColor;

  .listItemButtonText {
    font-weight: 500;
    margin-left: 24px;
  }
}

.selectedListItemButtonWrapper {
  background-color: $white;
  border-right: 5px solid $mustard300;
  box-shadow: rgba(0, 0, 0, 0.16) 0 1px 4px;
  margin-left: 7px;

  .selectedListItemButtonText {
    font-weight: bold;
    margin-left: 16px;
  }
}
