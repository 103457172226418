@import '../../../styles/colors';
@import '../../../styles/metrics';
@import '../../../styles/mixins';

$tableHeader: $lightGrayBlue;

.dataRow {
  background-color: inherit;
  border: 0;
  box-shadow: 0 -1px 0 $lightGrayBlue;
  cursor: pointer;
  height: 45px;

  > td {
    font-size: 14px;
    font-weight: normal;
    height: 44px;
    line-height: 17px;
    padding: 0 5px;
    text-align: center;
  }
}

.dataRow:hover {
  background-color: $tableHeader;
}

.tagFinishedTowing,
.tagOngoingTowing,
.tagCompliantStatus,
.tagOverdueStatus,
.tagNoInfoStatus {
  font-weight: bold !important;
  justify-content: center;
  min-width: fit-content !important;
  padding: 0 15px;
  width: 120px;
}

.tagFinishedTowing,
.tagOngoingTowing {
  text-transform: uppercase;
}

.tagFinishedTowing {
  background-color: $white !important;
  border-color: $lightGray !important;
  border-width: 1px;
  color: $black !important;
}

.tagOngoingTowing {
  background-color: $green !important;
  color: $white !important;
}

.tagCompliantStatus {
  background-color: $lightGreen !important;
}

.tagOverdueStatus {
  background-color: $lightRed !important;
}

.tagNoInfoStatus {
  background-color: $lightGray !important;
}

.dateField {
  color: $middleGray;
  display: inline-block;
  margin-right: 5px;
}

.timeField {
  display: inline-block;
}

.menuItemText {
  display: inline-flex;
  font-size: 16px;
  line-height: 19px;
  margin-left: 5px;
  padding-left: 10px;
}

.editButton {
  background: transparent !important;
  border-color: transparent;
  border-radius: 15px !important;
  height: 30px !important;
  min-width: 28px !important;
  padding-left: 0 !important;
  padding-right: 0 !important;
  width: 30px !important;

  > span {
    display: flex;
    justify-content: center;
  }
}

.editButton:hover {
  background: $lightGray !important;
}
