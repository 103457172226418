@import '../../../styles/colors';
@import '../../../styles/metrics';
@import '../../../styles/mixins';

html {
  overflow-y: hidden;
}

.outerContentWrapper {
  display: flex;
  flex: 1;
  flex-direction: column;
}

.buttonsWrapper {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  .flightListControls {
    display: flex;
    flex-direction: row;
    gap: 10px;
    justify-content: flex-start;
  }

  .newFlightButton {
    border-color: $carbon;
    display: flex;
    height: 34px;
    justify-content: space-between;
    padding: var(--chakra-space-1) var(--chakra-space-3);
  }

  .newFlightButtonText {
    margin: 0 10px;
  }
}

.innerContentWrapper {
  background-color: $white;
  border-top: 2px solid $mustard500;
  display: flex;
  flex-direction: column;
  height: calc(100vh - 170px);
  padding: 15px;
}

.ganttTableWrapper {
  align-items: center;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: flex-start;
  justify-content: flex-start;
  max-height: calc(100vh - 240px) !important;
  overflow: hidden;
  padding: 1rem;
  padding-top: 16px;
  width: 100%;
}

.ganttLoadingWrapper {
  z-index: 2;
}

.flightListWrapper {
  flex-grow: 1;
  justify-content: space-between;
  margin-top: 1rem;
  overflow-y: hidden;
}

.configFilters {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

.configFlightPage {
  display: flex;
  flex-direction: row;
  height: 2.5rem;
  justify-content: space-between;
}

.hotKeys:focus {
  outline: 0;
}
