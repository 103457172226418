@import '../../../styles/colors';
@import '../../../styles/metrics';
@import '../../../styles/mixins';

.card-flex {
  align-items: center;
  background-color: #f0f0f0;
  display: flex;
  flex-direction: column;
  height: calc(100% - 4.5rem);
  justify-content: flex-start;
  max-width: 100%;
  overflow-y: auto;
  padding: 0.5rem 0.5rem 6rem;
  row-gap: 0.5rem;
  width: 100%;
}

.separator {
  font-size: 1.5em;
  width: 100%;
}

@media screen and (max-width: 800px) {
  .separator {
    font-size: 2.5em;
    width: 100%;
  }
}
