@import '../../../styles/colors';
@import '../../../styles/metrics';
@import '../../../styles/mixins';

.mainContent {
  gap: 1rem;
  margin-top: 1rem;
  overflow: auto;

  padding-bottom: 18rem;

  header,
  form {
    max-width: 1024px;
    width: 80vw;
  }

  header li {
    color: $charcoal;
    font-size: 14px;
    font-weight: 600;
    height: 2rem;

    letter-spacing: 0.1em;
    line-height: 14px;

    text-transform: uppercase;

    &.here {
      color: $gray;
    }

    svg {
      margin: 0 0.8rem;

      path {
        fill: $charcoal;
      }
    }
  }

  .templateForm {
    background-color: $white;
    border-radius: 0.5rem;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    padding: 1.5rem;

    h2 {
      font-size: 18px;
      font-weight: 700;
      line-height: 22px;
    }
  }
}

.moreButton {
  align-items: center;
  background-color: $white !important;
  display: flex;
  height: 36px !important;
  justify-content: center;
  min-width: unset !important;
  padding-left: 0 !important;
  padding-right: 0 !important;
  width: 28px !important;

  > span {
    align-items: center;
    display: flex;
    justify-content: center;
  }

  &:hover {
    background-color: $lightGrayBlue2 !important;
  }
}

.moreMenu {
  border-radius: 8px !important;
  overflow: hidden;
  padding: 0 !important;

  > button {
    padding: var(--chakra-space-3);
  }
}

.menuMoreDeleteItem {
  color: $darkGray;

  &:hover {
    background-color: $urgency !important;

    svg {
      path {
        stroke: $white;
      }
    }
    color: $white;
  }
}

.textItemsButtonMore {
  font-family: Lato;
  font-size: 16px;
  font-style: normal;
  font-weight: normal;
  line-height: 19px;
  padding-left: 10px;
}

.scheduleForms {
  background-color: $white;
  border-radius: 8px;
  height: auto;
  max-width: 1024px;
  width: 80vw;
}

.scheduleTitle {
  display: flex;
  justify-content: space-between;
  padding: 30px 24px 0;

  .scheduleTitleLabel {
    font-family: Lato;
    font-size: 18px;
    font-weight: 700;
    letter-spacing: 0;
    line-height: 22px;
    text-align: left;
  }

  .removeButton {
    align-items: center;
    background-color: $white !important;
    display: flex;
    height: 36px !important;
    justify-content: center;
    min-width: unset !important;
    padding-left: 0 !important;
    padding-right: 0 !important;
    width: 122px !important;

    > span {
      align-items: center;
      display: flex;
      justify-content: center;
    }

    &:hover {
      background-color: $lightGrayBlue2 !important;
    }
  }
}

.divAddButton {
  display: flex;
  justify-content: flex-end;
  max-width: 1024px;
  width: 80vw;
}

.addButton {
  align-items: center;
  background: #fff;
  display: flex;
  flex-direction: row;
  gap: 8px;
  height: 40px;

  justify-content: space-between;
  padding: 8px 16px;
  width: 180px;

  > span {
    align-items: center;
    display: flex;
    justify-content: center;
  }
}
