@import '../../../styles/colors';
@import '../../../styles/metrics';
@import '../../../styles/mixins';

.tag {
  align-items: center;
  background-color: $lighterGray;
  border-radius: 4px;
  display: flex;
  flex-direction: row;

  height: 28px;
  justify-content: center;

  > button {
    height: 100%;
  }
}

.labelKey {
  font-weight: bold;
}

.textLabel {
  font-size: 14px;
  font-style: normal;
  font-weight: normal;
  line-height: 14px;
  margin: 5px 5px 5px 15px;
}

.listTags {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 0.5rem;
}

.hasTags {
  margin-top: 0.8rem;
}
