@import '../../../styles/colors';
@import '../../../styles/metrics';
@import '../../../styles/mixins';

.loadingWrapper {
  align-items: center;
  background-color: $white;
  border-radius: 0.5rem;
  bottom: 55px;
  box-shadow: 0 0 0.5rem 0 #0000002c;
  display: flex;
  flex-direction: row;
  height: 2.2rem;
  justify-content: center;
  position: fixed;
  right: 0.8rem;
  width: 2.2rem;

  > div {
    margin: 0;
  }
}
