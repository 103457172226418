@import '../../../styles/colors';
@import '../../../styles/metrics';
@import '../../../styles/mixins';

.container {
  border: 1px solid $lighterGray;
  border-radius: 4px;
  width: 100%;

  .header {
    min-height: 48px;
    padding: 10px 16px;

    .title {
      color: $middleDarkGray;
      font-size: $mediumFontSize;
      font-weight: 700;
      line-height: 1;
      margin-right: 10px;
    }

    .arrow {
      align-items: center;
      border-left: 1px solid $lighterGray;
      cursor: pointer;
      display: flex;
      height: 20px;
      margin-left: 16px;
      padding-left: 16px;

      svg {
        transform: rotate(0deg);
        transition: ease-in-out;
      }
    }
  }

  .body {
    border-top: 1px solid $lighterGray;
    padding: 8px 16px 16px;
  }

  &.open {
    .header {
      .arrow {
        svg {
          transform: rotate(-180deg);
        }
      }
    }
  }
}
