@import '../../../styles/colors';
@import '../../../styles/metrics';
@import '../../../styles/mixins';

.logoContainer {
  align-items: center;
  background: $white;
  border: 1px solid $lightGray;
  border-radius: 5px;
  display: flex;
  flex: 1;
  height: 100%;
  justify-content: center;
  overflow: hidden;

  img {
    width: 100%;
  }
}
