@import '../../../styles/colors';
@import '../../../styles/metrics';
@import '../../../styles/mixins';

// sass-lint:disable function-name-format

.fids-status-container {
  align-items: center;
  border-radius: 8px;
  display: flex;
  gap: 16px;
  height: 64px;
  padding: 8px 16px;
  width: 100%;
  span {
    display: flex;
    svg {
      height: 0.7em;
      width: 0.7em;
    }
  }

  p {
    font-size: 20px;
  }

  .text-status {
    color: var(--core-colors-low-pure-500, #1d1d1d);
    display: flex;
    flex-direction: column;
    font-family: Lato;
    font-size: 24px;
    font-style: normal;
    font-weight: 800;
    height: 24px;
    height: 100%;
    justify-content: center;
    line-height: 150%;
    overflow: hidden;
    text-overflow: ellipsis;
    vertical-align: middle;
    white-space: nowrap;
    span p {
      vertical-align: middle;
    }
  }

  @media screen and (max-width: 800px) {
    p {
      font-size: inherit;
      height: auto;
    }
  }
}
