@import '../../../styles/colors';
@import '../../../styles/metrics';
@import '../../../styles/mixins';

.centerMain {
  align-items: center;
  color: $white;
  display: flex;
  flex-direction: column;
  width: 100%;
}

.loginMain {
  align-self: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.loginMS {
  align-items: center;
  align-self: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.swapLogin {
  cursor: 'pointer' !important;
  margin-top: '10px';
  text-align: center;
}

.swapLogin {
  color: #808080;
  font-size: 0.7em;
}

.loginButtonIcon {
  margin-right: 5px;
}
