@import '../../../styles/colors';
@import '../../../styles/metrics';
@import '../../../styles/mixins';

.navbarWrapper {
  align-items: center;
  caret-color: transparent;
  left: 0;
  min-height: 50px;
  padding: 0 2vw;
  position: fixed;
  width: 100vw;
  z-index: 70;
}

.displayEnvironmentBar {
  top: 40px;
}

.hideEnvironmentBar {
  top: 0;
}

.navbarHamburgerFiller {
  width: 50px;
}

.arrowDown {
  align-self: center;
  margin-left: 10px;
  width: 16px;
}

.titlePage {
  color: $white;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0.1em;
  line-height: 16px;
  text-align: center;
  text-transform: uppercase;
}

.airportIndicator {
  align-items: center;
  height: 34px;
  justify-content: space-around;
}

.aresIconWrapper {
  align-items: center;
  display: flex;
  flex-direction: row;
  margin-right: 10px;
}

.airportName {
  color: $white;
  font-family: Lato;
  font-size: 18px;
  font-style: normal;
  font-weight: bold;
  line-height: 22px;
}

.airportMenuButton {
  align-items: center;
  border: 1px solid $white;
  border-radius: 4px;
  box-sizing: border-box;
  display: flex;
  flex: 1;
  flex-direction: row;
  gap: 8px;
  height: 32px;
  max-width: 150px;
  padding: 4px 10px;
  width: 100px;

  > span {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-around;
  }
}

.airportNameItemMenu {
  color: $middleGray;
  font-family: Lato;
  font-size: 14px;
  font-style: normal;
  font-weight: normal;
  line-height: 17px;
}

.airportCodeItemMenu {
  color: $middleGray;
  font-family: Lato;
  font-size: 18px;
  font-style: normal;
  font-weight: bold;
  line-height: 22px;
  text-align: center;
}

.menuItemAirport {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;

  p:last-of-type {
    margin-left: 32px;
  }
}

.menuUser {
  color: $middleGray;
  font-family: Lato;
  font-size: 14px;
  font-style: normal;
  font-weight: normal;
  line-height: 17px;
}

.navbarHamburguerMenu {
  margin-right: 50px;
}

.userDisplayWrapper {
  justify-self: flex-end;
  margin-right: 1vw;
  position: relative;
}

.userDisplayText {
  color: $white;
  font-family: Lato;
  font-size: 14px;
  font-style: normal;
  font-weight: bold;
  line-height: 17px;
  text-align: center;
}

.ccrWhiteIcon {
  align-items: center;
  height: 26px;
  width: auto;
}
