@import '../../../styles/colors';
@import '../../../styles/metrics';
@import '../../../styles/mixins';

.toggleCard {
  align-items: flex-start;
  background-color: $white;
  border-radius: 0.5rem;
  display: flex;
  flex-direction: column;

  font-weight: bold;
  gap: 0.5rem;

  justify-content: stretch;
  padding: 1rem;

  position: relative;

  .closeButton {
    position: absolute;
    right: 1rem;
    top: 1rem;
    visibility: hidden;

    svg {
      path {
        stroke: $white;
      }
    }
  }

  &.collapse {
    .closeButton {
      order: 1;
      position: relative;
      right: 0;
      top: 0;
      visibility: visible;

      svg {
        width: 0.625rem;
      }
    }
  }
}

.toggleCard:hover {
  .closeButton {
    visibility: visible;
  }
}
