@import '../../../styles/colors';
@import '../../../styles/metrics';
@import '../../../styles/mixins';

/* sass-lint:disable no-vendor-prefixes*/
.input {
  background: $white;
  border: 1px solid $lightGray;
  border-radius: 4px;

  box-sizing: border-box;
  color: $darkGray;
  font-size: 15px;
  font-weight: normal;

  height: 40px;
  line-height: 18px;
  padding: 8px 0 8px 35px;
  width: 100%;

  &:focus {
    border: 1px solid $mustard;
  }

  &:active {
    border: 1px solid $mustard;
  }

  &.errors {
    border-color: $error;
  }

  &::-webkit-calendar-picker-indicator {
    background: none;
  }
}

.field {
  padding: 0;
}
