@import '../../../styles/colors';
@import '../../../styles/metrics';
@import '../../../styles/mixins';

.fieldContainer {
  box-sizing: border-box;
  padding: 0 $spacer 10px;
  width: 100%;
}

.fieldLabel {
  color: $standardTextColor;
  font-family: Arial;
  font-size: $smallFontSize;
  line-height: $smallLineHeight;
  margin: 0 0;
  width: 100%;

  .fieldRequired {
    color: $primary;
  }
}

.field {
  position: relative;
}

.error {
  color: $error;
  font-size: $smallFontSize;
  line-height: $smallLineHeight;
}
