@import '../../../styles/colors';
@import '../../../styles/metrics';
@import '../../../styles/mixins';

.choiceRadios label span:first-of-type {
  border-color: $darkGray;
  border-width: 1px;
  height: var(--chakra-sizes-5);
  justify-content: space-around;
  width: var(--chakra-sizes-5);

  &[data-checked] {
    background: $white;
    color: $mustard;

    &::before {
      align-self: center;
      height: 60%;
      width: 60%;
    }
  }

  &[data-focus] {
    box-shadow: 0 0 0 3px $mustard500;
  }

  &[data-hover] {
    background: $mustard50;
  }

  &[data-disabled] {
    background: $white;
    border-color: var(--chakra-colors-gray-100);
  }
}

.choiceRadios label span[data-disabled] {
  cursor: not-allowed;
  opacity: 0.7 !important;
}

.choiceRadiosStack {
  height: 39px;
}

small.errors {
  color: $error;
}
