@import '../../../styles/colors';
@import '../../../styles/metrics';
@import '../../../styles/mixins';

.flightNumberContainer {
  align-items: center;
  display: inline-flex;

  > *:not(:first-child) {
    margin-left: 1.5vw;
  }
}
