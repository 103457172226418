@import '../../../styles/colors';
@import '../../../styles/metrics';
@import '../../../styles/mixins';

.sectionHeader {
  align-items: center;

  .sectionTitle {
    font-size: 18px;
    font-style: normal;
    font-weight: bold;
    line-height: 22px;
    margin-left: 10px;
  }
}

.formMoreDetailsButton {
  color: $mustard !important;
  font-size: 14px;

  :hover {
    color: $mustard !important;
  }
}

.formSection {
  scroll-margin: 20px;
}

.flightIssueMessage {
  color: $mustard500;
}
