@import '../../../styles/colors';
@import '../../../styles/metrics';
@import '../../../styles/mixins';

.leftSide {
  align-items: center;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
}

.timeRefreshFlightListText {
  font-size: 14px;
  font-weight: normal;
  line-height: 15px;
  margin-left: 5px;
}

@keyframes toggleBackground {
  0% {
    background-color: $white;
    color: $urgency;
  }

  25% {
    background-color: rgba($urgency, 0.15);
    color: rgba($urgency, 0.75);
  }

  50% {
    background-color: rgba($urgency, 0.5);
    color: $white;
  }

  75% {
    background-color: $urgency;
    color: $white;
  }
}

.timeRefreshHighlightedFlightListText {
  animation: toggleBackground 1s infinite;
  border: 1px solid $urgency;
  border-radius: 4px;
  color: $urgency;
  font-size: 14px;
  font-weight: bold;
  line-height: 15px;
  margin-left: 5px;
  padding: 5px;
}

.rightSide {
  align-items: center;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
}
