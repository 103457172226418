@import '../../../styles/colors';
@import '../../../styles/metrics';
@import '../../../styles/mixins';

.fieldAddValue {
  display: flex;
  width: 100% !important;

  &:hover {
    svg {
      path {
        stroke: $primary;
      }
    }
  }
}

.fieldDisplayValue {
  color: var(--chakra-colors-gray-800);
  font-weight: normal;
  max-width: 7rem;
  overflow: hidden;
  text-overflow: ellipsis;
}

.fieldEditValueButton:disabled {
  opacity: 1 !important;
}
