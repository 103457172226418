.react-datepicker-wrapper {
  width: 100%;
}


.react-datepicker-popper {
  z-index: 150;
}

.react-datepicker * {
  font-family: var(--chakra-fonts-body);
}

.react-datepicker-popper[data-placement^="bottom"] {
  padding-top: 0.188rem;
}

.react-datepicker {
  display: flex;
  border: 1px solid #ebebeb !important;
  border-radius: 0.313rem;
  background-color: var(--chakra-colors-white);
}

.react-datepicker .react-datepicker__triangle {
  display: none;
}

.react-datepicker .react-datepicker-time__header,
.react-datepicker .react-datepicker__header {
  background-color: var(--chakra-colors-white);
  border-color: #ebebeb;
}

.react-datepicker .react-datepicker-time__header,
.react-datepicker .react-datepicker__current-month {
  color: #383838;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 0.75rem;
  letter-spacing: 0.06em;
}

.react-datepicker .react-datepicker__navigation {
  border: none !important;
}

.react-datepicker .react-datepicker__navigation-icon--previous:before,
.react-datepicker .react-datepicker__navigation-icon--next:before {
  border-width: 1px 1px 0 0;
  top: 0.625rem;
}

.react-datepicker .react-datepicker__day-name {
  color: #ADADAD;
  text-transform: uppercase;
}

.react-datepicker .react-datepicker__day {
  color:  #383838;
}


.react-datepicker .react-datepicker__day--in-range {
  background-color: var(--chakra-colors-ccr-100);
  border-color: var(--chakra-colors-ccr-100) !important;
  border-radius: 50%;
}

.react-datepicker .react-datepicker__day--range-start,
.react-datepicker .react-datepicker__day--range-end {
  background-color: var(--chakra-colors-ccr-100);
  border-color: var(--chakra-colors-ccr-500) !important;
}

.react-datepicker .react-datepicker__day--keyboard-selected,
.react-datepicker .react-datepicker__month-text--keyboard-selected,
.react-datepicker .react-datepicker__quarter-text--keyboard-selected,
.react-datepicker .react-datepicker__year-text--keyboard-selected,
.react-datepicker .react-datepicker__day--selected,
.react-datepicker .react-datepicker__day:hover {
  background-color: var(--chakra-colors-ccr-500);
  border-color: var(--chakra-colors-ccr-500) !important;
  border-radius: 50%;
  color: var(--chakra-colors-white);
}

.react-datepicker .react-datepicker__day {
  border: 1px solid transparent;
  width: 1.813rem;
  height: 1.813rem;
  border-radius: 50%;
}

.react-datepicker .react-datepicker__day--keyboard-selected,
.react-datepicker .react-datepicker__day--today,
.react-datepicker .react-datepicker__day--disabled:hover {
  font-weight: bold;
  border-color: var(--chakra-colors-ccr-500);
  border-radius: 50%;
}

.react-datepicker .react-datepicker__day--today {
  background-color: var(--chakra-colors-white);
  color:  #383838;
}

.react-datepicker .react-datepicker__day--disabled {
  color: rgba(53, 53, 53, 0.5);
}

.react-datepicker .react-datepicker__time-container {
  border-color: #ebebeb;
}

.react-datepicker .react-datepicker__time-list-item {
  color:  #383838;
}

.react-datepicker .react-datepicker__time-list-item:hover {
  background-color: var(--chakra-colors-ccr-500) !important;
  color: var(--chakra-colors-white);
}

.react-datepicker .react-datepicker__time-list-item--selected {
  font-weight: bold;
  background-color: var(--chakra-colors-ccr-500) !important;
  color: var(--chakra-colors-white);
}

.react-datepicker__close-icon {
  border: none !important;
  padding: 0 8px 0 0;
}

.react-datepicker__close-icon:after {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 20px;
  width: 20px;
  background-color: transparent;
  content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' height='20' width='20' viewBox='0 0 20 20' aria-hidden='true' focusable='false' class='css-tj5bde-Svg'%3E%3Cpath d='M14.348 14.849c-0.469 0.469-1.229 0.469-1.697 0l-2.651-3.030-2.651 3.029c-0.469 0.469-1.229 0.469-1.697 0-0.469-0.469-0.469-1.229 0-1.697l2.758-3.15-2.759-3.152c-0.469-0.469-0.469-1.228 0-1.697s1.228-0.469 1.697 0l2.652 3.031 2.651-3.031c0.469-0.469 1.228-0.469 1.697 0s0.469 1.229 0 1.697l-2.758 3.152 2.758 3.15c0.469 0.469 0.469 1.229 0 1.698z' fill='%23ADADAD'%3E%3C/path%3E%3C/svg%3E");
}

.react-datepicker__close-icon:hover:after {
  content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' height='20' width='20' viewBox='0 0 20 20' aria-hidden='true' focusable='false' class='css-tj5bde-Svg'%3E%3Cpath d='M14.348 14.849c-0.469 0.469-1.229 0.469-1.697 0l-2.651-3.030-2.651 3.029c-0.469 0.469-1.229 0.469-1.697 0-0.469-0.469-0.469-1.229 0-1.697l2.758-3.15-2.759-3.152c-0.469-0.469-0.469-1.228 0-1.697s1.228-0.469 1.697 0l2.652 3.031 2.651-3.031c0.469-0.469 1.228-0.469 1.697 0s0.469 1.229 0 1.697l-2.758 3.152 2.758 3.15c0.469 0.469 0.469 1.229 0 1.698z' fill='%23858585'%3E%3C/path%3E%3C/svg%3E");
}
