@import '../../../styles/colors';
@import '../../../styles/metrics';
@import '../../../styles/mixins';

.shortcutButton {
  align-items: center;
  column-gap: 0.5rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

button.newConstructionButton {
  border-color: $carbon;
  display: flex;
  height: 34px;
  justify-content: space-between;
  padding: var(--chakra-space-1) var(--chakra-space-3);
}

.newConstructionButtonText {
  margin: 0 10px;
}

.deleteButton:hover {
  background-color: $urgency !important;

  svg {
    path {
      stroke: $white;
    }
  }
  color: $white;
}
