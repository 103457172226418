@import '../../../styles/colors';
@import '../../../styles/metrics';
@import '../../../styles/mixins';

.towingMovementItem {
  font-size: 16px;
  width: 100%;
}

.towingMovementItemDetails {
  width: 100%;

  > * {
    width: 20%;
  }
}

.towingDate {
  color: $secondaryTextColor;
}

.towingActionButton {
  p {
    color: $standardTextColor;
    font-size: 16px;
  }

  svg path {
    stroke: $standardTextColor;
  }
}

.hiddenButton {
  visibility: hidden;
}

.movementsReadOnlyLabel {
  font-weight: bold;
}
