// Responsive
$mobile: 375px;
$mobileMin: 374px;
$tablet: 768px;
$tabletMin: 767px;
$desktop: 1440px;
$desktopMin: 1439px;
$desktopHeight: 1024px;
$mobileHeight: 667px;
$maxDesktopContainer: 1240px;

// Padding / Margin
$spacer: 15px;
$spacer2x: $spacer * 2;

// Height
$buttonHeight: 39px;

// Width
$buttonWidth: 150px;
$buttonSmallWidth: 100px;
$buttonLargeWidth: 200px;

// Layout
$borderRadius: 5px;

// Fonts
$smallFontSize: 12px;
$mediumFontSize: 14px;
$smallLineHeight: 15px;
$mediumLineHeight: 16px;
$regularFontSize: 16px;

// Fids
$fidsNavbarHeight: 9.4vh;
$fidsFooterHeight: 6.2vh;
$fidsFooterCompactHeight: 4.5vh;

$ganttColumnMinWidth: 5.875rem;
$ganttGapBetweenColumns: var(--chakra-space-2);

:export {
  borderRadius: $borderRadius;
  buttonHeight: $buttonHeight;
  buttonLargeWidth: $buttonLargeWidth;
  buttonSmallWidth: $buttonSmallWidth;
  buttonWidth: $buttonWidth;
  fidsFooterCompactHeight: $fidsFooterCompactHeight;
  fidsFooterHeight: $fidsFooterHeight;
  fidsNavbarHeight: $fidsNavbarHeight;
  ganttColumnMinWidth: $ganttColumnMinWidth;
  ganttGapBetweenColumns: $ganttGapBetweenColumns;
  spacer: $spacer;
}
