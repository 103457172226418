@import '../../../styles/colors';
@import '../../../styles/metrics';
@import '../../../styles/mixins';

.moreButton {
  align-items: center;
  background-color: transparent !important;
  border-radius: 14px !important;
  display: flex;
  height: 24px !important;
  justify-content: center;
  min-width: unset !important;
  padding-left: 0 !important;
  padding-right: 0 !important;
  width: 24px !important;

  > span {
    align-items: center;
    display: flex;
    justify-content: center;
  }

  &:hover {
    background-color: var(--chakra-colors-gray-400) !important;

    svg {
      path {
        stroke: var(--chakra-colors-gray-300);
      }
    }
    transition: all 125ms linear;
  }
}

.menuMoreDeleteItem {
  color: $darkGray;

  &:hover {
    background-color: $urgency !important;

    svg {
      path {
        stroke: $white;
      }
    }
    color: $white;
  }
}

.textItemsButtonMore {
  font-size: 16px;
  font-weight: normal;
  line-height: 19px;
  padding-left: 10px;
}

.moreMenu {
  border-radius: 8px !important;
  overflow: hidden;
  padding: 0 !important;

  > button {
    padding: var(--chakra-space-3);
  }
}

.menuItemWrapper {
  height: 43px;
  width: 222px;
}

.icon {
  height: 16px;
  width: 16px;
}
