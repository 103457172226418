@import '../../styles/colors';
@import '../../styles/metrics';
@import '../../styles/mixins';

.appContainer {
  background-color: $white;
  display: flex;
  flex-direction: column;
  height: 100vh;
}
