@import '../../../styles/colors';
@import '../../../styles/metrics';
@import '../../../styles/mixins';

.inlineFieldEdit {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: max-content;
  justify-content: center;
  width: 100%;

  input {
    border: 0;
    cursor: pointer;
    font-size: 14px;
    height: var(--chakra-space-7);
    max-width: 55px;
    padding-inline-end: var(--chakra-space-2);
    padding-inline-start: var(--chakra-space-2);
    text-align: center;
    width: fit-content;

    &:disabled {
      color: $carbon !important;
      opacity: 1;
    }

    // sass-lint:disable no-vendor-prefixes
    &[type='time']::-webkit-calendar-picker-indicator {
      display: none;
    }
  }

  &.errors {
    border-color: $error;
    color: $error;
  }
}

.addValueButton {
  background: transparent;
  border: 0;
  border-radius: 1.5rem;
  margin: auto;
  min-width: fit-content !important;
  padding: 0;
  transition: all 0.25ms linear;
  width: 100% !important;

  &.hidden {
    height: 0 !important;
    margin: 0 !important;
    overflow: hidden !important;
    padding: 0 !important;
    transition: all 0.25ms linear;
    width: 0 !important;

    * {
      display: none !important;
    }
  }

  svg {
    margin: auto;
  }

  &:hover {
    svg {
      path {
        stroke: $primary;
      }
    }
  }
}

.hidden {
  height: 0 !important;
  overflow: 0 !important;
  transition: all 0.25ms linear;

  width: 0 !important;
}

.errors {
  color: $error;
}
