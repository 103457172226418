@import '../../../../../styles/colors';
@import '../../../../../styles/metrics';
@import '../../../../../styles/mixins';

.standHeader,
.standHeader > * {
  align-items: center;
  height: 15vh;
  overflow: hidden;
  width: 100%;
}

.standHeader img {
  max-width: fit-content;
}

.standFlightNumber {
  justify-content: flex-end;
}

.logo {
  background-color: inherit;
  border: 0;

  img {
    height: 100%;
    object-fit: scale-down;
    width: auto;
  }
}

.standBody {
  color: $defaultFidsDarkGray;
  height: 100%;
  justify-content: space-around;
  text-align: center;
  width: 100%;

  > * {
    height: initial;
  }
}
