@import '../../../../../styles/colors';
@import '../../../../../styles/metrics';
@import '../../../../../styles/mixins';

.restScreenContainer {
  background-color: $defaultFidsBlue;
  color: $white;
  display: flex;
  flex-direction: column;
  font-family: 'Sora';
  height: 100vh;
  justify-content: center;
  padding: 4rem;
  position: relative;
  width: 100vw;
}

.restScreenMessage {
  .restScreenTitle {
    color: $white;
    font-size: 5.3rem;
    font-weight: 700;
    line-height: 6.5rem;
    text-wrap: wrap;
  }

  h2 {
    font-size: 3.3rem;
    margin-top: 3.5rem;
  }
}

.restScreenCcrLogo {
  align-self: flex-end;
  bottom: 3rem;
  position: absolute;
  width: 255px;
}
