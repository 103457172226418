@import '../../../styles/colors';
@import '../../../styles/metrics';
@import '../../../styles/mixins';

.stickyHeader {
  background-color: $white;
  box-shadow: inset 0 -1px 0 $lighterGray;
  left: -1rem;
  padding: 0.5rem 0;
  position: relative;
  right: 0;
  top: -1rem;
  width: 100vw;
}

.returnIcon {
  margin-right: 8px;
}

.returnLink {
  margin-left: 8px;
}

.returnLinkText {
  color: $carbonoGray;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0.1em;
  line-height: 14px;
  text-align: center;
  text-transform: uppercase;
}

.dateAndTime,
.flightLogoAndIdentity {
  align-items: center;
  display: flex;
  padding: 0 8px;
}

.newFlightText {
  color: $dashboardDivider;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0.1em;
  line-height: 14px;
  text-align: center;
  text-transform: uppercase;
}

.status {
  display: flex;
  flex-direction: row;
  padding: 0 8px;
}

.returnLink {
  padding: 0 8px;
}

.breadcrumbContainer {
  ol {
    align-items: center;
    display: flex;
    height: 100%;
  }

  li {
    min-height: 26px;
  }
}

.chatModalButton {
  border-color: $lighterGray !important;
  color: $middleDarkGray !important;
  display: flex;
  flex-direction: row;
  font-size: 12px;
  font-weight: 600;
  gap: 0.5rem;
  height: 2rem !important;
  letter-spacing: 0.1em;
  line-height: 14px;
  margin-right: 1rem;
  text-align: center;
}
