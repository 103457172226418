@import '../../../styles/colors';
@import '../../../styles/metrics';
@import '../../../styles/mixins';

.popoverLinkedFlightLabel {
  color: $carbon;
  font-size: $smallFontSize;
  font-weight: bold;
  line-height: $smallLineHeight;
}

.popoverLinkedFlightAnchor {
  align-items: center;
  border: 1px solid $lightGray;
  border-radius: 4px;
  display: flex;

  justify-content: space-between;
  padding: var(--chakra-space-2) var(--chakra-space-3);
}

.linkedFlightAddValueButton {
  display: flex;
  width: 100% !important;

  &:hover {
    svg {
      path {
        stroke: $primary;
      }
    }
  }
}

.fieldDisplayValue {
  color: var(--chakra-colors-gray-800);
  font-weight: normal;
  max-width: 7rem;
  overflow: hidden;
  text-overflow: ellipsis;
}

.smallFlightFieldText {
  max-width: 4.5rem !important;
}
