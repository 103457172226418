@import '../../../styles/colors';
@import '../../../styles/metrics';
@import '../../../styles/mixins';

// sass-lint:disable function-name-format
/*
  Esta variável fidsNumberRow serve para indicar a quantidade de linhas na tabela,
  de forma a calcular a altura de cada linha.
  O componente FidsTable irá sobrescrever este valor com a quantidade real de linhas
  a serem renderizadas, considerando também a linha dos headers.
*/
$brand-primary-dark: #123456;
$grayscale-dark: #333;
$grayscale-lighter-gray: #e5e5e5;

:root {
  @include rootProp(--fidsNumberRow, 10);
  @include rootProp(--fidsNumberExtraRows, 0);
}

.italicized {
  font-style: italic;
}

.dark-blue {
  color: $brand-primary-dark;
}

.fids-card-container {
  align-items: center;
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0 8px 24px 0 #1d1d1d29;
  color: $grayscale-dark;
  column-gap: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0.5rem;
  row-gap: 0.5rem;
  width: 100%;

  .top-row {
    align-items: center;
    display: flex;
    flex-direction: row;
    font-size: 16px;
    font-weight: 700;
    height: 3.5rem;

    justify-content: space-between;
    letter-spacing: 0;
    line-height: 38px;
    text-align: left;
    width: 100%;

    .airline-logo-slot {
      align-items: center;
      border-radius: 4px;
      display: flex;
      justify-content: center;
      max-height: 3.5rem;
      max-width: 10rem;
      overflow: hidden;

      img {
        height: unset;
        width: unset;
        width: 100%;

        &.small-airline-logo {
          display: initial;
        }

        &.large-airline-logo {
          display: none;
        }

        &.small-airline-logo {
          display: none;
        }
      }
    }
  }

  .divider {
    background-color: $grayscale-lighter-gray;
    height: 2px;
    width: 100%;
  }

  .bottom-row {
    align-items: center;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    row-gap: 1rem;
    width: 100%;

    .airport-slot {
      align-items: flex-start;
      display: flex;
      flex: 1;
      flex-direction: column;
      justify-content: center;
      padding: 0 0.5rem;
      text-wrap: nowrap;
    }

    .status-slot {
      align-items: flex-start;
      display: flex;
      flex: 1;
      flex-direction: column;
      justify-content: center;
      margin-left: calc(20% - 100px);
      max-height: 40px;
      padding: 0 0.5rem;
      text-wrap: nowrap;

      span p {
        font-size: 24px;
      }

      span svg {
        height: 28px;
        width: 28px;
      }
    }

    .time-slot {
      align-items: center;
      column-gap: 1rem;
      display: flex;
      flex: 2;
      flex-direction: row;
      justify-content: space-between;
      padding: 0 0.5rem;
    }
  }
}

.flight-number {
  font-size: 3em;
  margin-right: 10px;
}

.data-pair {
  align-items: stretch;
  display: flex;
  flex-direction: column;
  justify-content: center;

  .label {
    column-gap: 0.2rem;
    display: flex;
    font-size: 16px;
    font-weight: 500;
    height: 36px;
    letter-spacing: 0.1em;
    line-height: 18px;
    text-align: left;
    text-transform: uppercase;
  }

  .value {
    column-gap: 0.2rem;
    display: flex;
    font-size: 16px;
    font-weight: 400;
    height: 32px;
    letter-spacing: 0;
    line-height: 24px;
    text-align: left;
  }
}

@media screen and (max-width: 800px) {
  .fids-card-container {
    align-items: center;
    background-color: #fff;
    border-radius: 4px;
    box-shadow: 0 8px 24px 0 #1d1d1d29;
    color: $grayscale-dark;
    column-gap: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 1000px;
    padding: 2rem 1rem;
    row-gap: 0.5rem;
    width: 100%;

    .top-row {
      align-items: center;
      display: flex;
      flex-direction: row;
      font-size: 32px;
      font-weight: 700;
      height: 4.5rem;

      justify-content: space-between;
      letter-spacing: 0;
      line-height: 38px;
      text-align: left;
      width: 100%;

      .airline-logo-slot {
        align-items: center;
        border-radius: 4px;
        display: flex;
        justify-content: center;
        max-height: 4.5rem;
        max-width: 10rem;
        overflow: hidden;

        img {
          height: unset;
          width: unset;
          width: 100%;

          &.small-airline-logo {
            display: initial;
          }

          &.large-airline-logo {
            display: none;
          }

          &.small-airline-logo {
            display: none;
          }
        }
      }
    }

    .divider {
      background-color: $grayscale-lighter-gray;
      height: 2px;
      width: 100%;
    }

    .bottom-row {
      align-items: stretch;
      display: flex;
      flex-direction: column;
      height: max-content;
      justify-content: flex-start;
      row-gap: 1rem;
      width: 100%;

      .airport-slot {
        width: 100%;
      }

      .time-slot {
        align-items: center;
        column-gap: 10%;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
        width: 100%;
      }

      .status-slot {
        margin-left: 0;
        width: 100%;
      }

      .airport-slot,
      .status-slot {
        align-items: center;
        display: flex;
        flex-direction: row;
        height: 100%;
        justify-content: flex-start;
        text-wrap: nowrap;
        width: 100%;
      }
    }
  }

  .flight-number {
    font-size: 2em;
  }

  .data-pair {
    align-items: stretch;
    display: flex;
    flex-direction: column;
    height: 100px;
    justify-content: center;

    .label {
      column-gap: 0.2rem;
      display: flex;
      font-size: 36px;
      font-weight: 500;
      height: 36px;
      letter-spacing: 0.1em;
      line-height: 18px;
      text-align: left;
      text-transform: uppercase;
    }

    .value {
      column-gap: 0.2rem;
      display: flex;
      font-size: 32px;
      font-weight: 400;
      height: 32px;
      letter-spacing: 0;
      line-height: 24px;
      text-align: left;
    }
  }
}
