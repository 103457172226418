@import '../../../styles/colors';
@import '../../../styles/metrics';
@import '../../../styles/mixins';

.escButton {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  padding: 10px;
  position: relative !important;
  right: unset !important;
  top: unset !important;

  > div {
    margin-right: 10px;
  }
}
