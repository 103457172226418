@import '../../../styles/colors';
@import '../../../styles/metrics';
@import '../../../styles/mixins';

.delete {
  color: $white;
  font-weight: bold;
  margin-right: 10px;
}

.cancel {
  color: $carbon;
  margin-right: 10px;
}

.bold {
  font-weight: bold;
}

$tableHeader: $lightGrayBlue;

table {
  text-align: center;
  width: 100%;
}

.tableHeader {
  background-color: $tableHeader;
  width: 100%;
}

.tableHeader > tr > th {
  background-color: $tableHeader;
  font-family: Lato;
  font-size: 13px;
  font-style: normal;
  font-weight: bold;
  line-height: 14px;
  padding: 12px 0;
  position: sticky;
  text-transform: uppercase;
  top: 0;
  z-index: 1;
}

.tableBody {
  font-size: 14px;
}

.dataRow {
  background-color: inherit;
  border: 0;
  box-shadow: 0 -1px 0 $lightGrayBlue;
  cursor: pointer;
  height: 45px;

  > td {
    font-family: Lato;
    font-size: 14px;
    font-style: normal;
    font-weight: normal;
    line-height: 17px;
    padding: 0 5px;
  }
}

.dataRow:hover {
  background-color: $tableHeader;
}

.flightIconField {
  display: flex;
  justify-content: center;

  svg {
    margin: 0;
  }
}

.dateField {
  color: $middleGray;
  display: inline-block;
  margin-right: 5px;
}

.timeField {
  display: inline-block;
}
