@import '../../../styles/colors';
@import '../../../styles/metrics';
@import '../../../styles/mixins';

.shortcutWrapper {
  align-items: center;
  background-color: $white;
  border: 1px solid $lightGray;
  border: 1px solid rgba(156, 156, 156, 0.5);
  border-radius: 2px;
  box-shadow: 0 2px 0 rgba(156, 156, 156, 0.5);
  display: flex;
  height: 20px;
  justify-content: center;
  padding: 5px;
  width: fit-content;
}

.shortcutSelected {
  border: 1px solid $mustard600;
  box-shadow: 0 2px 0 $mustard600;
}

.shortcutText {
  color: $black;
  font-family: Lato;
  font-size: $smallFontSize;
  font-style: normal;
  font-weight: normal;
  line-height: 14px;
  text-align: center;
}
