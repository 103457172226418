@import '../../../styles/colors';
@import '../../../styles/metrics';
@import '../../../styles/mixins';

.groupName {
  color: $secondaryTextColor;
  font-size: 14px;
  font-style: normal;
  letter-spacing: 0.05em;
  line-height: 17px;
}

.groupDivider {
  border: 1px solid $lightGray !important;
  margin-top: 20px !important;
}

.mainLayout {
  background-color: $white;
  border-radius: 8px;
  display: grid;

  gap: 32px;
  grid-template-rows: [header filters contentTable pagination] 6vh minmax(max-content, 8vh) auto 6vh;
  height: calc(100vh - 118px);
  margin: 12px 0 0 24px;
  padding: 36px 24px;

  header {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .pageTitle {
      align-items: flex-start;
      display: flex;
      flex-direction: column;
      justify-content: center;

      h1 {
        color: $carbonoGray;
        font-size: 14px;
        font-weight: 600;
        letter-spacing: 0.1em;
        text-transform: uppercase;
      }

      h2 {
        color: $darkGray;
        font-size: 24px;
        font-weight: 700;
      }
    }
  }

  div.filterWrapper {
    align-items: stretch;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    .filterControls {
      align-items: center;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }

    .filterControlsRightSide {
      display: flex;
      flex-direction: row;

      .refreshButton {
        margin-right: -6px;
      }
    }

    .searchBarInput {
      height: 30px;
      width: 35vw;
    }
  }

  section.contentWrapper {
    height: 100%;
    overflow: scroll;
  }

  footer {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: center;

    ol {
      justify-self: center;
    }

    .pageCountSelect {
      position: absolute;
      right: calc(32px + 0.5rem);
      width: 10rem;
    }
  }
}

.emptyState {
  display: flex;
  flex-direction: column;
  height: 50vh;
  justify-content: center;

  .circleTextWrapper {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: center;
  }

  .circleIcon {
    align-items: center;
    background: $lightGrayBlue;
    border-radius: 50%;
    display: flex;
    flex-direction: row;
    gap: 16px;
    height: 40px;
    justify-content: center;
    padding: 0;
    width: 40px;
  }

  .boldText {
    color: $darkGray;
    font-size: 120%;
    font-weight: 700;
    margin-left: 1.25%;
    text-align: center;
  }

  .text {
    color: $darkGray;
    font-size: 110%;
    font-weight: 400;
    margin-top: 49px;
    text-align: center;
  }

  .stepsWrapper {
    align-items: center;
    display: flex;
    flex-direction: row;
    gap: 72px;
    justify-content: center;
    vertical-align: top;
  }

  .singleStepWrapper {
    align-self: center;
    height: 200px;
    margin-top: 24px;

    .circleWrapper {
      margin-left: -20px;
      margin-top: 10px;
      position: absolute;
    }

    .circleStep {
      align-items: center;
      background: $lighterGray;
      border-radius: 50%;
      display: flex;
      flex-direction: row;
      gap: 16px;
      height: 40px;
      justify-content: center;
      padding: 0;
      width: 40px;
    }

    .number {
      color: #616161;
      flex: none;
      flex-grow: 0;
      font-size: 24px;
      font-weight: 700;
      height: 24px;
      line-height: 24px;
      order: 0;
      width: 14px;
    }

    .imageDescription {
      color: #616161;
      font-size: 14px;
      font-weight: 400;
      height: 34px;
      line-height: 17px;
      text-align: center;
      width: 232px;
    }
  }
}

.templateModalForm {
  .templateModalFooter {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;

    button {
      margin-left: 1rem;
    }
  }
}

.buttonAddTemplate svg {
  margin-right: 10px;

  path {
    stroke: $white;
  }
}

.reportsSidemenu {
  overflow-y: auto;
}
