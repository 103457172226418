@import '../../../styles/colors';
@import '../../../styles/metrics';
@import '../../../styles/mixins';

.fidsStatus {
  align-items: center;
  font-weight: 800;
  height: 100%;
  overflow: hidden;
  width: 100%;
}
