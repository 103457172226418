@import '../../../styles/colors';
@import '../../../styles/metrics';
@import '../../../styles/mixins';

// sass-lint:disable function-name-format

.icon-color-dt svg path {
  fill: #f83446;
}

.icon-color-de svg path {
  stroke: #f6c643;
}

.icon-color-es svg path {
  stroke: #666;
}

.icon-color-co svg path {
  fill: #00d4a1;
}

.icon-color-ot svg path {
  stroke: #00d4a1;
}

.icon-color-ga svg path {
  fill: #f6c643;
}

.icon-color-bd svg path {
  fill: #00d4a1;
}

.icon-color-lc svg path {
  fill: #f83446;
}

.icon-color-bc svg path {
  fill: #666;
}

.icon-color-ab svg path {
  fill: #00d4a1;
}

.icon-color-fx svg path {
  fill: #666;
}

.icon-color-td svg path {
  fill: #f6c643;
}

.icon-color-dm svg path {
  fill: #f6c643;
}

.icon-color-ca svg path {
  fill: #f83446;
}

.icon-color-nc svg path {
  stroke: #feebec;
}

.icon-color-ra svg path {
  fill: #f6c643;
}

.icon-color-ac svg path {
  fill: #666;
}

.icon-color-cc svg path {
  fill: #666;
}

.icon-color-la svg path {
  fill: #00d4a1;
}

.icon-color-co svg path {
  fill: #00d4a1;
}

.icon-color svg path {
  fill: #000;
}
