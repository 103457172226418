@import '../../../../styles/colors';
@import '../../../../styles/metrics';
@import '../../../../styles/mixins';

.contentTable {
  table-layout: fixed;

  th,
  td {
    padding-left: 6px;
    text-align: left;
  }

  th {
    font-size: 11px;
    font-weight: 600;
    height: 32px;
    letter-spacing: 0.1em;
    line-height: 14px;
    overflow: hidden;
    text-overflow: ellipsis;
    text-transform: uppercase;
    white-space: nowrap;
  }

  td {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    height: 42px;
    line-height: 17px;

    .statusText,
    .fileName,
    .templateName {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  tr {
    box-shadow: inset 0 -1px 0 #e4e9f1;

    .largeCell {
      padding-right: 1rem;
    }

    .smallCell {
      padding-right: 0.5rem;
      text-align: center;
    }

    .editCell {
      width: 3rem;

      button {
        padding: 0 10px;
      }
    }

    .fileNameCell,
    .startedAtCell,
    .expirationDateCell,
    .statusCell {
      padding-right: 0.5rem;
    }

    .fileNameCell {
      width: 30%;
    }

    .startedAtCell,
    .expirationDateCell {
      width: 15%;
    }

    .statusCell {
      width: 26%;
    }

    .emailsCell {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      width: 25%;
    }

    .reportTypeCell {
      width: 17%;
    }

    .reportTemplateCell {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      width: 19%;
    }

    .presetCell {
      width: 13%;
    }

    .hourCell {
      text-align: center;
      width: 14%;
    }

    .dayCell {
      text-align: center;
      width: 6%;
    }

    .weekdayCell {
      text-align: center;
      width: 12%;
    }

    &:hover {
      background-color: $lightGrayBlue;
    }
  }

  thead > tr {
    background-color: $lightGrayBlue;
    position: sticky;
    top: 0;
    z-index: 2;
  }

  .generateCell {
    width: 5rem;
  }

  .downloadCell {
    width: 12%;
  }

  .generateCell,
  .downloadCell {
    button {
      font-size: 12px !important;
      font-weight: 600 !important;
      height: 36px !important;
      letter-spacing: 0.1em !important;
      line-height: 14px !important;
      margin: 0 5px;
      padding: 0 10px !important;
      text-transform: uppercase !important;

      svg {
        margin-right: 0.5rem;
      }
    }
  }
}

.fileNameField {
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
}

.fileFormatIcon {
  align-items: center;
  border-radius: 4px;
  color: $white;

  display: flex;
  font-size: $smallFontSize;

  font-weight: 600;
  height: 2.3rem;

  justify-content: center;
  letter-spacing: 0.1em;
  line-height: 14px;
  margin-right: 0.5rem;
  min-width: 2.3rem;
  text-transform: uppercase;

  &.csvIcon {
    background-color: #eccf6a;
  }

  &.xlsxIcon {
    background-color: #b7e98f;
  }

  &.txtIcon {
    background-color: #a3d5cf;
  }
}

.statusField svg {
  margin-right: 5px;
  min-height: 20px;
  min-width: 20px;
}

.dateField {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.moreButton {
  align-items: center;
  background-color: $white !important;
  display: flex;
  height: 36px !important;
  justify-content: center;
  min-width: unset !important;
  padding-left: 0 !important;
  padding-right: 0 !important;
  width: 28px !important;

  > span {
    align-items: center;
    display: flex;
    justify-content: center;
  }
}

.moreMenu {
  border-radius: 8px !important;
  overflow: hidden;
  padding: 0 !important;

  > button {
    padding: var(--chakra-space-3);
  }
}

.trTemplateCell {
  cursor: pointer;
}
