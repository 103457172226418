@import '../../../styles/colors';
@import '../../../styles/metrics';
@import '../../../styles/mixins';

// sass-lint:disable function-name-format
/*
  Esta variável fidsNumberRow serve para indicar a quantidade de linhas na tabela,
  de forma a calcular a altura de cada linha.
  O componente FidsTable irá sobrescrever este valor com a quantidade real de linhas
  a serem renderizadas, considerando também a linha dos headers.
*/
:root {
  @include rootProp(--fidsNumberRow, 10);
  @include rootProp(--fidsNumberExtraRows, 0);
}

/*
  Aqui, foi removido 1vh extra da altura do body; isso porque, ao usar o número
  exato disponível, a altura das linhas era arredondada - e arredondada para cima.
  Isso tornava o body maior do que o espaço disponível, empurrando o cabeçalho da tabela
  para baixo da navbar.
*/
$bodyHeight: calc(100vh - var(--fidsNavbarHeight) - var(--fidsFooterHeight) - 1vh);

$headerRowSize: 5.5vh;
$fidsTableHeight: calc(#{$bodyHeight} - #{$headerRowSize});
$initialRowSize: calc(#{$fidsTableHeight} / var(--fidsNumberRow));
$extraRowSize: calc(#{$initialRowSize} * 0.75);
$totalExtraRowHeight: calc(#{$extraRowSize} * var(--fidsNumberExtraRows));
$rowSize: calc((#{$fidsTableHeight} - #{$totalExtraRowHeight}) / var(--fidsNumberRow));

$fontSize: Min(#{$rowSize} * 0.56, 3vw);

$headerFontSize: Min(#{$headerRowSize} * 0.53, 1.8vw);

$staffFontSize: Min(#{$rowSize} * 0.5, 3.85vh);

$staffHeaderFontSize: Min(#{$headerRowSize} * 0.4, 3.2vh);

$paddingVertical: 0.9vh;
$paddingHorizontal: 0.8vw;
$paddingHorizontalStaff: 0.4vw;
$paddingRow: $paddingVertical $paddingHorizontal;
$paddingEdges: 1.2vw;

.table {
  box-sizing: border-box;
  font-family: Lato;
  height: 100%;
}

.head {
  height: $headerRowSize;

  .header {
    color: $fidsTableHeaderTextColor;
    font-size: $headerFontSize;
    line-height: $headerFontSize;
    padding: $paddingRow;
    text-transform: uppercase;
  }

  .header:first-of-type {
    padding-left: $paddingEdges;
  }

  .header:last-of-type {
    padding-right: $paddingEdges;
  }
}

.body {
  .row {
    height: $rowSize;
  }

  .dateSeparatorRow {
    height: $extraRowSize;
  }

  .column {
    color: $fidsTableHeaderBodyColor;
    font-size: $fontSize;

    height: inherit;
    padding: $paddingRow;

    .columnData {
      align-items: center;
      height: calc(#{$rowSize} - 2 * #{$paddingVertical} - 1px);
    }
  }

  .columnImage {
    padding-bottom: calc(#{$paddingVertical} / 4);

    padding-top: calc(#{$paddingVertical} / 4);

    .columnData {
      height: calc(#{$rowSize} - (#{$paddingVertical}/ 2) - 1px);
    }
  }

  .column:first-of-type {
    padding-left: $paddingEdges;
  }

  .column:last-of-type {
    padding-right: $paddingEdges;
  }
}

@media only screen and (max-width: 970px) {
  .staff {
    .header {
      font-size: calc(#{$staffHeaderFontSize} * 0.9) !important;
    }
  }
}

.header *,
.column * {
  display: flex;
}

.viaHeaderDeparture,
.viaHeaderDepartureShortened,
.viaHeaderReclaimBelt,
.viaHeaderArrival,
.viaHeaderArrivalShortened {
  color: $defaultFidsBlue;
}

.viaHeaderDepartureShortened,
.viaHeaderArrivalShortened {
  margin-left: 2px !important;
}

.reclaimBeltTable {
  .head {
    height: 10vh;
  }

  .body .column {
    font-size: calc(#{$rowSize} * 0.35);
  }
}

.staff {
  .header {
    font-size: $staffHeaderFontSize;
    line-height: $staffHeaderFontSize;
    padding: $paddingVertical $paddingHorizontalStaff;
  }

  .body .column {
    font-size: $staffFontSize;
  }
}

@media only screen and (max-width: 970px) {
  .head {
    .header {
      font-size: calc(#{$headerFontSize} * 0.8);
      font-weight: bold;
    }
  }
}
