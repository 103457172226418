@import '../../../styles/colors';
@import '../../../styles/metrics';
@import '../../../styles/mixins';

.mainButton {
  border: 1px solid $lightGray;
  border-radius: 4px;
  box-sizing: border-box;

  outline: 2px solid transparent;
  outline-offset: 2px;
  vertical-align: middle;

  > span {
    align-items: center;
    display: flex;
    flex-direction: row;
    margin-right: 12px;
  }

  svg {
    margin-right: 5px;
  }
}

.divButton {
  margin-left: 16px;
}

.divButtonSubmit {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 15px;
  margin-top: 32px;
}

.buttonSubmit {
  font-family: Lato;
  font-size: $smallFontSize;
  font-style: normal;
  font-weight: bold;
  line-height: 17px;

  > span {
    margin-right: 68.67px;
  }
}

.divButtonReset {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 15px;
  margin-top: 32px;
}

.buttonFilter {
  font-family: Lato;
  font-size: 14px;
  font-style: normal;
  font-weight: bold;
  line-height: 17px;
  width: 170px;
}

.buttonFilterText {
  color: $darkGray;
}

.modalSection {
  width: max-content;
}

.divDivider {
  margin-left: 16px;
  margin-right: 16px;
}
