@import '../../../styles/colors';
@import '../../../styles/metrics';
@import '../../../styles/mixins';

.hamburguerMenu {
  caret-color: transparent;
  padding-top: 40px !important;
}

.hamburguerMenuFooter {
  padding-bottom: 2rem !important;
}

.hamburguerMenu,
.hamburguerMenuFooter {
  background-color: $lightGrayBlue;
}

.ccrBlueIcon {
  margin-left: 12px;
  width: 125px;
}
