@import '../../../styles/colors';
@import '../../../styles/metrics';
@import '../../../styles/mixins';

.flightDetailsWrapper {
  font-family: Lato;
  overflow-y: auto;

  &:focus {
    outline: none;
  }
}

.disabledText {
  opacity: 0.7;
}

.sectionForm {
  margin: 1.5rem 0;

  .sectionDivisor {
    margin-top: 2rem !important;
  }
}

.formControl:not(:last-child) {
  margin-right: 1rem;
}

.flightInfoWrapper {
  flex: 1;
  margin-left: var(--chakra-space-5);
  overflow: auto;
  overflow-y: scroll;
}

input.errors {
  border-color: $error;
  color: $error;
}

small.errors {
  color: $error;
}

.generalAviation {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
}

.booleanCheckboxContainer {
  align-items: flex-end;
  display: flex;
  margin-bottom: 10px;
  margin-top: var(--chakra-space-4);
  min-height: 64px;
}

.hybridDisembarkWrapper {
  align-items: flex-end;
  display: grid;
  margin-top: var(--chakra-space-4);
}

.hybridDisembarkCheckboxWrapper {
  align-items: flex-start;
  display: flex;
}

.hybridDisembarkCheckbox {
  width: auto;
}

.hybridDisembarkDetails {
  margin-top: var(--chakra-space-2);
}

.airportFieldForm {
  flex: 1;
  width: inherit !important;
}
