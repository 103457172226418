@import '../../../styles/colors';
@import '../../../styles/metrics';
@import '../../../styles/mixins';

.cancelButton {
  color: $carbon;
  font-size: 14px;
  font-style: normal;
  font-weight: bold;
  line-height: 17px;
  padding-left: 10px;
}

.saveButton {
  font-family: Lato;
  font-size: 14px;
  font-style: normal;
  font-weight: bold;
  line-height: 17px;
}
