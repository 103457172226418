@import '../../../styles/colors';
@import '../../../styles/metrics';
@import '../../../styles/mixins';

.input {
  background: $white;
  border: 2px solid $standardTextColor;
  border-radius: 4px;
  box-sizing: border-box;
  color: $standardTextColor;
  font-size: $smallFontSize;
  font-weight: 700;
  height: 31px;
  line-height: $smallLineHeight;
  padding: 8px 0 8px 12px;
  width: 100%;

  &::placeholder {
    color: $standardTextColor;
    font-size: $smallFontSize;
    font-weight: normal;
    opacity: 1;
  }
}
