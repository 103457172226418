@import '../../../styles/colors';
@import '../../../styles/metrics';
@import '../../../styles/mixins';

.footer {
  background-color: $white;
  bottom: 0;
  display: flex;
  height: 48px;
  left: 0;
  padding: var(--chakra-space-1) var(--chakra-space-3);
  position: fixed;
  width: 100%;
  z-index: 10;
}

.stackFooter {
  flex: 1;
  height: 100%;
  justify-content: space-between;
}
