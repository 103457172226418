@import '../../../styles/colors';
@import '../../../styles/metrics';
@import '../../../styles/mixins';

.containerComments {
  margin-top: var(--chakra-space-2);
}

.readonlyFieldLabel {
  font-size: var(--chakra-fontSizes-md);
  font-weight: normal;
}

.readonlyFieldText {
  color: $black;
  font-size: var(--chakra-fontSizes-md);
}

.gridCardRequest {
  min-width: 0;

  > * {
    min-width: 0;
  }
}

.statusTag {
  align-items: center;
  border-radius: 4px;
  display: flex;
  flex-direction: row;
  font-size: 14px;
  font-weight: 600;
  line-height: 17px;
  padding: 4px 8px;
}

.finished,
.cancelled {
  background: $lighterGray;
  color: $darkGray;
}

.approved {
  background: $ambuliftBgGreen;
  color: $ambuliftTextGreen;
}

.waiting,
.pending {
  background: $ambuliftBgYellow;
  color: $ambuliftTextYellow;
}

.changeNotSaved {
  margin-left: 10px;

  p {
    color: $highAlertColor;
    font-size: 14px;
    line-height: 17px;
    margin-left: 4px;
  }
}
