$black: rgb(0, 0, 0);
$white: rgb(255, 255, 255);
$error: rgb(241, 79, 74);
$urgency: rgb(194, 42, 42);
$carbon: rgb(94, 94, 94);
$charcoal: rgb(97, 97, 97);
$middleGray: rgb(156, 156, 156);
$gray: rgb(173, 173, 173);
$green: #00c000;

$transparent: rgba(255, 255, 255, 0);

$mustard50: #fff7dd;
$mustard100: #f8e7b4;
$mustard200: #f3d78a;
$mustard300: #edc75d;
$mustard400: #e7b631;
$mustard500: #e3ad1a;
$mustard600: #af8514;
$mustard700: #725708;
$mustard800: #463401;
$mustard900: #1b1000;

$mustard: $mustard500;
$primary: $mustard;

$grayscaleDarkerBlack: #1d1d1d;
$grayscaleBlack: #383838;
$darkGray: #3d3d3d;
$middleDarkGray: #616161;
$midToneGray: #979797;
$lighterMiddleDarkGray: rgba(168, 168, 168, 0.4);
$carbonoGray: #5d5d5d;
$carbonoLighterGray: #858585;
$lighterGray: #ebebeb;
$lightGray: #dedede;
$lightGreen: #E6F8F4;
$lightRed: #FEE7EC;
$standardTextColor: $darkGray;
$secondaryTextColor: #9c9c9c;

$lightGrayBlue: #fafafa;
$lightGrayBlue2: #edf2f7;

$disabledComponents: $lighterMiddleDarkGray;

// FIDS
$defaultFidsBlue: #14307f;
$defaultFidsDarkGray: $darkGray;
$fidsGray: #f8f8f8;
$fidsNavbarTextColor: $white;

$fidsTableHeaderColor: $fidsGray;
$fidsTableOddRowsColor: $white;
$fidsTableEvenRowsColor: $fidsGray;
$fidsTableBorderColor: #dadada;
$fidsTableHeaderTextColor: $defaultFidsDarkGray;
$fidsTableHeaderBodyColor: $defaultFidsDarkGray;
$fidsTableCardLightBlue: #164299;
$fidsTableCardHeaderTextColor: #fff;

$closedResourceBackground: $defaultFidsBlue;

$resourceScreenBackground: $lightGrayBlue;
$resourceCardBorder: $lightGray;
$resourceCardBackground: $white;
$resourceCardHeader: $lightGrayBlue;
$resourceCardHeaderTextColor: $defaultFidsBlue;

$resourceBeltLandingColor: #0d7d55;

// Tower

$lightArrivalGreen: #e7f9d9;
$lightDepartureBlue: #e5f1fe;
$tableLineBorder: #e4e9f1;
$tableHighlight: #fdfaf1;

// Dashboard

$dashboardDivider: #adadad;
$highAlertColor: #f43d00;
$mediumAlertColor: #ffa048;
$lowAlertColor: #0079f2;
$hoverHighAlertColor: #9b2700;
$hoverMediumAlertColor: #d06400;
$hoverLowAlertColor: #0052a4;
$issueHoverBackgroundColor: #fafafa;
$flightBackgroundColor: #fafafa;
$flightTypeBorderColor: #ebebeb;
$flightHoverBackgroundColor: #ebebeb;

// Ambulift status

$ambuliftBgYellow: #ffe7c2;
$ambuliftTextYellow: #7a4800;
$ambuliftBgGreen: #ccffad;
$ambuliftTextGreen: #2f7a00;

$disabledDatepicker: #f2f2f2;

// Chat

$unreadMessagesCountYellowBadgeBackground: #ffd973;
$unreadMessagesCountYellowBadgeFont: #af8514;

// Gantt

$ganttLineBackground: $lighterGray;
$ganttDateControlGray: $dashboardDivider;
$ganttTooltip: $grayscaleDarkerBlack;
$ganttGreen: #065;
$ganttPastSegmentBackgroundColor: #979797;
$ganttDelayedFlightBackgroundColor: #c70014;
$ganttDelayedFlightFontColor: #52525b;
$ganttDelayedFlightIconColor: #f6c643;

// Gantt

$standardSegmentColor: #3d1d93;
$standardStandColor: #878888;

// Accessible by styled components
:export {
  carbon: $carbon;
  carbonoGray: $carbonoGray;
  closedResourceBackground: $closedResourceBackground;
  dashboardHighPriority: $highAlertColor;
  dashboardHoverHighPriority: $hoverHighAlertColor;
  dashboardHoverLowPriority: $hoverLowAlertColor;
  dashboardHoverMediumPriority: $hoverMediumAlertColor;
  dashboardLowPriority: $lowAlertColor;
  dashboardMediumPriority: $mediumAlertColor;
  defaultFidsBlue: $defaultFidsBlue;
  disabledComponents: $disabledComponents;
  error: $error;
  fidsNavbarTextColor: $fidsNavbarTextColor;
  fidsTableBorderColor: $fidsTableBorderColor;
  fidsTableEvenRowsColor: $fidsTableEvenRowsColor;
  fidsTableHeaderBodyColor: $fidsTableHeaderBodyColor;
  fidsTableHeaderColor: $fidsTableHeaderColor;
  fidsTableHeaderTextColor: $fidsTableHeaderTextColor;
  fidsTableOddRowsColor: $fidsTableOddRowsColor;
  flightBackgroundColor: $flightBackgroundColor;
  flightHoverBackgroundColor: $flightHoverBackgroundColor;
  flightTypeBorderColor: $flightTypeBorderColor;
  ganttDelayedFlightBackgroundColor: $ganttDelayedFlightBackgroundColor;
  ganttDelayedFlightFontColor: $ganttDelayedFlightFontColor;
  ganttDelayedFlightIconColor: $ganttDelayedFlightIconColor;
  ganttLineBackground: $ganttLineBackground;
  ganttPastSegmentBackgroundColor: $ganttPastSegmentBackgroundColor;
  ganttTooltip: $ganttTooltip;
  issueHoverBackgroundColor: $issueHoverBackgroundColor;
  middleDarkGray: $middleDarkGray;
  middleGray: $middleGray;
  mustard100: $mustard100;
  mustard200: $mustard200;
  mustard300: $mustard300;
  mustard400: $mustard400;
  mustard50: $mustard50;
  mustard500: $mustard500;
  mustard600: $mustard600;
  mustard700: $mustard700;
  mustard800: $mustard800;
  mustard900: $mustard900;
  notificationUserAvatarColor: $transparent;
  notificationUserAvatarTextColor: $grayscaleBlack;
  primary: $primary;
  standardSegmentColor: $standardSegmentColor;
  standardStandColor: $standardStandColor;
  standardTextColor: $standardTextColor;
}
