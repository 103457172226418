@import '../../../styles/colors';
@import '../../../styles/metrics';
@import '../../../styles/mixins';

.environmentBar {
  color: #fff;
  font-size: 0.75rem;
  font-weight: 700;
  height: 2.5rem;
  padding: 0.65rem;
  position: fixed;
  text-align: center;
  text-transform: uppercase;
  top: 0;
  width: 100vw;
  z-index: 100;
}

.dev {
  background-color: #4caf50;
}

.local {
  background-color: #8e44ad;
}

.hml {
  background-color: #f39c12;
  color: #1d1d1d;
}
