@import '../../../styles/colors';
@import '../../../styles/metrics';
@import '../../../styles/mixins';

// sass-lint:disable no-important
.dropdown {
  background: $white;
  border: 2px solid $standardTextColor !important;
  border-radius: 4px !important;
  box-sizing: border-box;
  color: $standardTextColor;
  font-size: $smallFontSize;
  font-weight: 700;
  height: 31px;
  line-height: $smallLineHeight;
  min-height: auto !important;
  outline: none;
  padding: 8px 12px !important;
  width: 100%;

  input {
    color: $standardTextColor;
    margin: 0;
    padding-top: 1px;

    &::placeholder {
      color: $standardTextColor;
      font-size: $smallFontSize;
      font-weight: normal;
      opacity: 1;
    }

    // sass-lint:disable no-vendor-prefixes
    &:-ms-input-placeholder {
      color: $standardTextColor;
    }

    &::-ms-input-placeholder {
      color: $standardTextColor;
    }
    // sass-lint:enable no-vendor-prefixes
  }

  // sass-lint:disable no-qualifying-elements
  div {
    border: 0;

    > span {
      &[role='option'] {
        background: $white;
        border: 0;
        color: $standardTextColor;

        &:hover,
        &[aria-selected='true'] {
          background: $primary;
          border: 0;
          color: $black;
        }
      }
    }

    &:last-child {
      top: 35px;
    }
  }
  // sass-lint:enable no-qualifying-elements
}
