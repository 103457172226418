@import '../../../styles/colors';
@import '../../../styles/metrics';
@import '../../../styles/mixins';

.popoverContent {
  min-width: fit-content;
}

.popoverHeader {
  align-self: flex-start;
  border-bottom: 0;
  font-size: 16px;
  font-weight: bold;
  margin-left: var(--chakra-space-1);
  margin-top: var(--chakra-space-3);
}

.popoverClose {
  margin-right: var(--chakra-space-4);
  margin-top: var(--chakra-space-3);
  min-width: fit-content;
  padding: 1rem 0.5rem !important;
  right: 0 !important;
  top: 0 !important;

  svg {
    margin-left: 5px;
  }
}

.popoverFooter {
  border-top: 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: var(--chakra-space-1);
  min-width: fit-content;

  button {
    justify-content: space-between;
    min-width: fit-content;
    width: 100%;
  }
}

.popoverBody {
  font-size: 14px;
  margin: var(--chakra-space-1) var(--chakra-space-3);
  padding: var(--chakra-space-1) !important;
}
