@import '../../../styles/colors';
@import '../../../styles/metrics';
@import '../../../styles/mixins';

.dateSeparator {
  align-items: center;
  background-color: $defaultFidsBlue;
  color: $white;
  display: flex;

  flex-direction: row;
  font-family: 'Lato';
  font-size: 80%;
  font-weight: 700;

  height: 100%;
  justify-content: center;

  line-height: 80%;
  margin: 0;
  padding: 0;
  width: 100%;

  svg {
    height: 3vh;
    margin: 0.7vh 1vh;
  }

  @media (max-width: 700px) {
    justify-content: flex-start;
    padding-left: 24px;
  }
}
