@import 'colors';
@import 'metrics';
@import 'mixins';

body {
  background-color: $white;
  color: $standardTextColor;
  font-family: Arial;
  font-size: $regularFontSize;
  font-weight: normal;
  letter-spacing: normal;
  line-height: 24px;
  text-align: left;
}

a {
  color: $primary;
}

h1 {
  color: $standardTextColor;
  font-size: 20px;
  font-style: normal;
  font-weight: normal;
  line-height: 20px;
}
