@import '../../../styles/colors';
@import '../../../styles/metrics';
@import '../../../styles/mixins';

$systemMessageSize: calc(max(450px, 70%));

.threadContainer {
  height: calc(100% - 50px);
  overflow-y: scroll;
}

.sendBoxIconContainer {
  padding: 0;
}

.disabled {
  cursor: not-allowed;

  &:hover {
    pointer-events: none;
  }
}

.systemMessage {
  align-items: center;
  border: 1px solid $lighterGray;
  border-radius: 8px;
  box-sizing: content-box;
  color: $grayscaleBlack;
  display: flex;
  flex-direction: column;
  font-family: 'Lato';
  gap: 8px;
  margin-bottom: 15px;
  margin-left: calc((100% - #{$systemMessageSize}) / 2);
  margin-top: 15px;
  padding: 8px 24px;
  text-align: center;
  width: $systemMessageSize;

  h1 {
    font-size: 16px;
    font-weight: 700;
    min-height: 14px;
  }

  h2 {
    color: $carbonoLighterGray;
    font-size: 11px;
    font-weight: 600;
    height: 14px;
    letter-spacing: 0.1em;
  }

  p {
    font-size: 12px;
    font-weight: 500;
    height: 14px;
    letter-spacing: 0.06em;
  }
}

.approved {
  background: $ambuliftBgGreen;
  color: $ambuliftTextGreen;
  transition: all 125ms linear;
}

.changeStatusButtonBase {
  align-items: center;
  box-sizing: border-box;
  display: flex;
  flex: none;
  flex-direction: row;
  flex-grow: 0;
  gap: 8px;
  order: 0;
  padding: 8px 16px;

  button {
    background: $white;
    border: 1px solid $lightGray;
    border-radius: 4px;
    color: $middleDarkGray;
    font-family: 'Lato';
    font-size: 12px;
    font-weight: 600;
    height: 32px;
    letter-spacing: 0.1em;
    line-height: 14px;
    text-align: center;
    text-transform: uppercase;
    width: 104px;
  }

  .approved:hover,
  .approvedSelected {
    background: $ambuliftBgGreen;
    color: $ambuliftTextGreen;
    transition: all 125ms linear;
  }

  .pending:hover,
  .pendingSelected {
    background: $ambuliftBgYellow;
    color: $ambuliftTextYellow;
    transition: all 125ms linear;
  }
}

.notificationBadge {
  background-color: $error;
  border-radius: 1rem;
  color: #fff;
  font-size: 8px;
  height: 11px;
  line-height: 11px;
  min-width: 11px;
  padding: 0 2px;
}

.openChatModal {
  align-items: center;
  border-radius: 14px;
  display: flex;
  height: 24px;
  justify-content: center;
  padding: 0%;
  padding-inline-end: 0%;
  padding-inline-start: 0%;
  position: relative;
  width: 24px;

  .notificationBadge {
    position: absolute;
    right: 2px;
    top: 2px;
  }
}

button.openChatModal:hover {
  background-color: var(--chakra-colors-gray-400);

  svg {
    path {
      fill: var(--chakra-colors-gray-300);
    }
  }
}
