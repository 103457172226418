@import '../../../styles/colors';
@import '../../../styles/metrics';
@import '../../../styles/mixins';

$tableHeader: $lightGrayBlue;

table {
  text-align: center;
  width: 100%;
}

.tableHeader {
  background-color: $tableHeader;
  width: 100%;
}

.tableHeader > tr > th {
  background-color: $tableHeader;
  font-family: Lato;
  font-size: 13px;
  font-style: normal;
  font-weight: bold;
  line-height: 14px;
  padding: 12px 0;
  position: sticky;
  text-transform: uppercase;
  top: 0;
  z-index: 1;
}

.tableBody {
  font-size: 14px;
}

.dataRow,
.expandedDataRow {
  background-color: inherit;
  border: 0;
  box-shadow: 0 -1px 0 $lightGrayBlue;
  cursor: pointer;
  height: 45px;

  > td {
    font-family: Lato;
    font-size: 14px;
    font-style: normal;
    font-weight: normal;
    line-height: 17px;
    padding: 0 4px;
  }
}

.dataRow:hover {
  background-color: $tableHeader;
}

.expandedDataRow {
  background-color: $tableHeader;

  > td {
    border-bottom: 2px solid $tableHeader !important;
    padding: 4px;
  }
}

.lastExpandedDataRow > td {
  border-bottom: 2px solid var(--chakra-colors-gray-100) !important;
}

.expandedParentRow {
  background-color: $tableHeader;

  > td {
    border-bottom: 2px solid $tableHeader !important;
    padding: 4px;
  }
}

.titledCellContent {
  flex-direction: column;
  height: 44px;
  justify-content: stretch;

  &.titleActive {
    justify-content: space-between;
  }

  .cellTitle {
    color: $middleGray;
    line-height: 14px;
    text-align: center;
  }
}

.rowControls {
  .expandButton,
  .retractButton {
    align-items: center;
    border-radius: 14px;
    display: inline-flex;
    height: 24px !important;

    justify-content: center;
    margin-right: 5px;
    padding: 0;
    width: 24px !important;

    &:hover {
      background-color: var(--chakra-colors-gray-400);
    }
  }

  .expandButton {
    background-color: transparent;

    svg {
      path {
        stroke: var(--chakra-colors-gray-300);
      }
    }
    transition: all 125ms linear;
  }

  .retractButton {
    background-color: var(--chakra-colors-gray-300);

    svg {
      path {
        stroke: $white;
      }
      transform: rotate(180deg);
    }
    transition: all 125ms linear;
  }
}

.listFlightWrapper {
  max-height: 100%;
  overflow-y: auto;
}

// sass-lint:disable no-vendor-prefixes
::-webkit-scrollbar {
  background: transparent;
  width: 0;
}

.flightFieldText {
  color: var(--chakra-colors-gray-800);
  font-weight: normal;
  max-width: 7rem;
  overflow: hidden;
  text-overflow: ellipsis;
}

.linkedFlightFieldText,
.smallFlightFieldText {
  max-width: 4.5rem !important;
}

.flightIconField {
  display: flex;
  justify-content: center;

  svg {
    margin: 0 5px;
  }
}

.dateField {
  color: $middleGray;
  display: inline-block;
  margin-right: 5px;
}

.timeField {
  display: inline-block;
}

.addLinkedFlightLine {
  svg path {
    stroke: $mustard;
  }

  &:hover {
    svg path {
      stroke: $mustard600;
    }
  }
}

.marginRight {
  margin-right: 5px;
}

.statusChangeForm {
  max-width: 20rem;
}
