@import '../../../styles/colors';
@import '../../../styles/metrics';
@import '../../../styles/mixins';

.checkboxParent {
  padding: 0 !important;
}

.checkboxContainer {
  cursor: pointer;
  display: flex;
  gap: 8px;
}

.label {
  cursor: pointer;
  font-size: var(--chakra-fontSizes-md);
  user-select: none;
}

.checkbox {
  border: 1px solid var(--chakra-colors-gray-800) !important; // sass-lint:disable-line no-important
  cursor: pointer;
  height: 18px !important; // sass-lint:disable-line no-important
  margin-right: calc($spacer / 2);
  user-select: none;
  width: 18px !important; // sass-lint:disable-line no-important
}

.description {
  color: $dashboardDivider;
  // margin: calc($spacer / 2) 0;
  font-size: $mediumFontSize;
  line-height: calc(var(--chakra-fontSizes-md) * 1.5);
}

.disabled {
  opacity: 0.7;

  .label {
    cursor: not-allowed;
  }
}
