@import '../../../../../styles/colors';
@import '../../../../../styles/metrics';
@import '../../../../../styles/mixins';

.header,
.header > * {
  align-items: center;
  height: 10vh;
  overflow: hidden;
  width: 100%;
}

.singleReclaimBelt {
  .header,
  .header > * {
    height: 15vh;
  }
}

.header img {
  max-width: fit-content;
}

.flightNumber {
  justify-content: end;
}

.logo {
  background-color: inherit;
  border: 0;
  flex: unset;

  img {
    height: 100%;
    object-fit: scale-down;
    width: auto;
  }
}

.body {
  color: $defaultFidsDarkGray;
  height: 100%;
  justify-content: space-around;
  text-align: center;
  width: 100%;

  > * {
    height: initial;
  }
}

.landingTime {
  color: $resourceBeltLandingColor;
  font-size: var(--resource-card-body-font-size);
  font-weight: bold;

  svg {
    height: calc(var(--resource-card-body-font-size) * 0.65);
    width: calc(var(--resource-card-body-font-size) * 0.65);

    path {
      stroke: $resourceBeltLandingColor;
    }
  }

  .time div {
    font-size: var(--resource-card-body-font-size);
    font-weight: bold;
  }
}

.bodyDoubleFlights {
  flex: 1;
  justify-content: space-between;

  div {
    font-size: calc(var(--resource-card-body-font-size) * 0.8);
  }

  .landingTime {
    font-size: calc(var(--resource-card-body-font-size) * 0.7);
  }

  .time div {
    font-size: calc(var(--resource-card-body-font-size) * 0.7);
  }
}

.doubleFlightOrigin {
  max-width: 50vw;
}

.singleReclaimBelt {
  .originDestination {
    font-size: 12vh;
    line-height: 14vh;
  }
}

.originDestination {
  font-weight: bold;
  text-align: center;
}
