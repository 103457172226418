@import '../../../styles/colors';
@import '../../../styles/metrics';
@import '../../../styles/mixins';

.towerTableWrapper {
  background-color: $white;
  border-radius: 0.5rem;
  overflow: scroll;
  width: 100%;
}

.towerTable {
  background-color: $white;
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
  overflow: scroll;
  table-layout: fixed;
  width: 100%;

  thead {
    th {
      border: 0;
      box-shadow: inset 0 -1px 0 $tableLineBorder;
      font-size: 11px;
      font-style: normal;

      font-weight: 600;
      height: 2rem;

      letter-spacing: 0.1em;
      line-height: 14px;
      position: sticky;
      text-transform: uppercase;
      top: 0;

      &:first-child {
        border-top-left-radius: 0.5rem;
      }

      &:last-child {
        border-top-right-radius: 0.5rem;
      }
    }
  }

  &.arrival {
    thead {
      th {
        background-color: $lightArrivalGreen;
      }
    }
  }

  &.departure {
    thead {
      th {
        background-color: $lightDepartureBlue;
      }
    }
  }

  tbody {
    tr {
      td {
        border: 0;
        box-shadow: inset 0 -1px 0 $tableLineBorder;
        color: $darkGray;
        font-size: 14px;

        font-style: normal;
        font-weight: 400;
        height: 2.5rem;
        line-height: 17px;
        padding: 0.5rem;
        text-align: center;

        strong {
          background-color: $lighterGray;
          border-radius: 5px;
          font-size: 14px;
          font-style: normal;
          font-weight: 700;
          line-height: 14px;
          padding: 0.2rem 0.5rem;
        }
      }

      &.recentlyUpdated {
        td {
          background-color: $tableHighlight;
        }
      }
    }
  }

  .identity,
  .schedule,
  .status {
    align-items: center;
    display: flex;
    flex-direction: row;
    gap: 0.7rem;
    justify-content: center;
  }

  .identity {
    svg {
      height: 24px;
      margin: 0;
      padding: 0;
      width: 12px;
    }
  }

  .schedule {
    .date {
      color: $secondaryTextColor;
    }
  }
}
