@import '../../../styles/colors';
@import '../../../styles/metrics';
@import '../../../styles/mixins';

:root {
  @include rootProp(--fidsNavbarHeight, #{$fidsNavbarHeight});
}

$navbarHeight: var(--fidsNavbarHeight);
$navbarFontSize: calc(#{$navbarHeight} * 0.55);
$navbarLogoSize: calc(#{$navbarFontSize} + 2vh);
$navbarClockSize: calc(#{$navbarFontSize} - 0.5vh);
$navbarLogoSize: calc(#{$navbarHeight} * 1.4);

.navbarWrapper {
  align-items: center;
  color: $fidsNavbarTextColor;
  font-family: Lato;
  font-size: $navbarFontSize;
  height: $navbarHeight;
  justify-content: space-between;
  left: 0;
  min-height: 50px;
  padding: 0 2vw;
  position: fixed;

  top: 0;
  width: 100vw;
  z-index: 10;
}

.navbarLogo img {
  height: $navbarLogoSize;
  object-fit: contain;
  padding: 5px;
  width: $navbarLogoSize;
}

.titleArea {
  align-items: center;
  display: flex;
  flex-direction: row;

  svg {
    height: $navbarFontSize;
    margin-right: 2vh;
    width: $navbarFontSize;
  }
}

.title {
  font-weight: bold;
  letter-spacing: 0.07em;
  text-transform: uppercase;
}

.clock {
  align-items: center;
  display: flex;

  svg {
    height: $navbarClockSize;
    width: $navbarClockSize;

    path {
      stroke: $fidsNavbarTextColor;
    }
  }

  div {
    font-size: $navbarClockSize;
  }
}
