@import '../../../styles/colors';
@import '../../../styles/metrics';
@import '../../../styles/mixins';

.textButtonReturn {
  font-size: 14px;
  font-weight: bold;
  line-height: 17px;
  margin-right: 10px;
}
