@import '../../../styles/colors';
@import '../../../styles/metrics';
@import '../../../styles/mixins';

.logoContainer {
  align-items: center;
  border: 1px solid $lightGray;
  border-radius: 4px;
  display: flex;
  height: 26px;
  justify-content: center;
  margin-right: 8px;
  overflow: hidden;
  position: static;
  width: 70px;

  img {
    height: 100%;
    width: 100%;
  }
}
