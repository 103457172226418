@import '../../../../styles/colors';
@import '../../../../styles/metrics';
@import '../../../../styles/mixins';

.modalTitle {
  align-items: center;
  color: $grayscaleBlack;
  display: flex;
  flex: 1;
  font-size: $regularFontSize;
  font-weight: 400;
  justify-content: space-between;
  line-height: $regularFontSize;
  padding-right: 0.5rem;
}

.modalTitleFlightInfos {
  align-items: center;
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  justify-content: flex-start;
}

.chatProviderContainer {
  border: 1px solid $lighterGray;
  border-left: 0;
  height: 60vh;
  padding: var(--chakra-space-2);
  position: relative;
  width: 100%;
}

.startMessage {
  align-items: center;
  align-self: stretch;
  box-shadow: 0 -1px 0 $mustard500;
  color: $mustard600;
  display: flex;
  flex: none;
  flex-direction: row;
  flex-grow: 0;
  font-family: 'Lato';
  font-size: $smallFontSize;
  font-style: normal;

  font-weight: 700;
  gap: 8px;
  justify-content: center;
  letter-spacing: 0.1em;
  line-height: 14px;
  order: 1;
  padding: 4px 0;
  text-align: center;
  text-transform: uppercase;
}

.sendBox {
  bottom: 0;
  position: absolute;
  width: calc(100% - (0.5rem * 2));
}

.openFlightButton {
  align-items: center;
  border: 0 !important;
  color: $mustard500 !important;
  display: flex;
  gap: 0.5rem;
  height: min-content !important;
  justify-content: center;
  padding: 0.5rem !important;

  svg {
    path {
      stroke: $mustard500;
    }
  }
}
