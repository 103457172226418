@import '../../../styles/colors';
@import '../../../styles/metrics';
@import '../../../styles/mixins';

.datePickerWrapper {
  align-items: center;
  border: 1px solid var(--chakra-colors-blackAlpha-300) !important;
  border-radius: 0.313rem;
  display: flex;
  height: min-content;
  justify-content: center;
  padding-left: 1.75rem;

  &:has(input[disabled]) {
    background-color: $disabledDatepicker;
    opacity: 0.7 !important;
  }
}

.field {
  padding-left: 0.75rem;
  text-align: left;
  width: min-content;
}

.field:disabled {
  background-color: hsl(0, 0%, 95%);
}

.inputDate {
  appearance: none;
  background: inherit;
  font-size: var(--chakra-fontSizes-md);
  height: var(--chakra-sizes-10);
  min-width: 0;
  outline: 2px solid transparent;
  outline-offset: 2px;
  padding: 0;
  position: relative;
  transition-duration: var(--chakra-transition-duration-normal);
  transition-property: var(--chakra-transition-property-common);
  width: 100%;

  .date {
    border: 0;
    font-size: 16px;
    line-height: var(--chakra-sizes-10);
    padding-left: 0.75rem;
  }
}

input.errors,
div.errors {
  border-color: $error;
  color: $error;

  * {
    border-color: $error;
  }
}

small.errors {
  color: $error;
}
