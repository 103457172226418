@import '../../../styles/colors';
@import '../../../styles/metrics';
@import '../../../styles/mixins';

.footerButton {
  height: 36px !important;
  justify-content: space-between !important;
  min-width: 200px !important;
}

.textButtonReturnFlightControl,
.textButtonSubmitAndReturn {
  font-size: 14px;
  font-weight: bold;
  line-height: 17px;
  margin-right: 15px;
}
