@import '../../../../../styles/colors';
@import '../../../../../styles/metrics';
@import '../../../../../styles/mixins';

.sectionHeader {
  align-items: center;

  .sectionTitle {
    font-size: 18px;
    font-style: normal;
    font-weight: bold;
    line-height: 22px;
  }

  .sectionSubtitle {
    color: $middleGray;
    font-size: 14px;
    line-height: 17px;
  }
}

.formMoreDetailsButton {
  color: $mustard !important;
  font-size: 14px;

  :hover {
    color: $mustard !important;
  }
}

.linkedFlightTitle {
  font-size: 24px;
  font-weight: 700;
  line-height: 29px;
  margin: var(--chakra-space-2) 0;
}

.linkedFlightField {
  margin-right: auto !important;
}

.linkedFlightReadOnlyLabel {
  font-size: var(--chakra-fontSizes-sm);
  font-weight: bold;
  margin-bottom: var(--chakra-space-1);
}

.linkedFlightReadOnlyText {
  color: $black;
  font-size: var(--chakra-fontSizes-sm);
}

.logoContainer {
  align-items: center;
  border: 1px solid $lightGray;
  border-radius: 4px;
  box-sizing: border-box;
  display: flex;
  height: 26px;
  justify-content: center;
  margin-right: 8px;
  overflow: hidden;
  position: static;
  width: 70px;
}

small.linkedFlightError {
  color: $error;
}
