@import '../../../styles/colors';
@import '../../../styles/metrics';
@import '../../../styles/mixins';

.defaultButtonWrapper {
  align-items: center;
  border-radius: 4px 4px 0 0;
  display: flex;
  gap: 8px;
  height: 48px;
  padding: 0 16px;
  width: fit-content;
}

.smallButtonWrapper {
  align-items: center;
  display: flex;
  gap: 8px;
  height: 32px;
  padding: 0 16px;
  width: fit-content;
}

.selectedTabBar {
  background-color: $mustard;
}

.defaultTabBar {
  background-color: $lightGray;
}

.tabBarText {
  color: $darkGray;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0.1em;
  line-height: 19px;
  text-transform: uppercase;
}
