@import '../../../styles/colors';
@import '../../../styles/metrics';
@import '../../../styles/mixins';

.refreshButton {
  border: 1px solid $lightGray;
  border-radius: 4px;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.spin svg {
  animation: spin 0.5s linear;
  animation-iteration-count: 1;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  50% {
    transform: rotate(-180deg);
  }

  100% {
    transform: rotate(-360deg);
  }
}
