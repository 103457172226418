@import '../../../styles/colors';
@import '../../../styles/metrics';
@import '../../../styles/mixins';

:root {
  @include rootProp(--resource-card-header-font-size, 10vh);
  @include rootProp(--resource-card-body-font-size, 10vh);
}

.resourceCardContainer {
  align-items: center;
  background: $resourceCardBackground;
  border: 0.1vh solid $resourceCardBorder;
  border-radius: 1vw;
  box-shadow: 0 0.01vw 1vw rgba(187, 187, 187, 0.3);
  display: flex;
  flex: 1;
  flex-direction: column;
  max-width: 90vw;
  overflow: hidden;
}
@media only screen and (max-width: 960px) {
  .resourceCardContainer {
    max-width: 97.5vw;
  }
}

.resourceHeader,
.resourceBody {
  align-items: center;
  justify-content: center;
  width: 100%;
}

.resourceHeader {
  background-color: $resourceCardHeader;
  border-bottom: 0.1vh solid $fidsTableBorderColor;
  border-top-left-radius: 1vw;
  border-top-right-radius: 1vw;
  color: $resourceCardHeaderTextColor;
  font-size: var(--resource-card-header-font-size);
  font-weight: bold;
  height: fit-content;
  line-height: var(--resource-card-header-font-size);
  max-height: 50%;
  padding: 3vh 3vw;

  svg {
    height: calc(var(--resource-card-header-font-size) * 0.7);
    width: calc(var(--resource-card-header-font-size) * 0.7);
  }
}

.resourceBody {
  border-bottom-left-radius: 1vw;
  border-bottom-right-radius: 1vw;
  flex: 1;
  font-size: var(--resource-card-body-font-size);
  line-height: calc(var(--resource-card-body-font-size) * 1.3);
  padding: 2vh 3vw;
}
