@import '../../../../../styles/colors';
@import '../../../../../styles/metrics';
@import '../../../../../styles/mixins';

.addButtonText {
  color: $mustard500;
  font-size: 12px;
  letter-spacing: 0.1em;
  line-height: 14px;
  margin-left: 0.5rem;
  text-transform: uppercase;
}
