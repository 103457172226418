@import '../../../styles/colors';
@import '../../../styles/metrics';
@import '../../../styles/mixins';

.resourceFidsAirport {
  color: $defaultFidsDarkGray;
  font-size: 12vh;
  font-weight: bold;
  line-height: 14vh;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
}
