@import '../../../styles/colors';
@import '../../../styles/metrics';
@import '../../../styles/mixins';

.flightFormWrapper {
  height: fit-content;
  margin-bottom: 50vh;
  width: 100%;
}

.flightFormSections {
  background-color: $white;
  border-radius: 8px;
  height: fit-content;
  margin-bottom: 50px;
  width: 100%;
}

.flightFormTitle {
  font-size: 24px;
  font-weight: 700;
  line-height: 29px;
  margin: var(--chakra-space-2) 0;
}
