@import '../../../styles/colors';
@import '../../../styles/metrics';
@import '../../../styles/mixins';

.infoBox {
  background-color: $white;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  margin: $spacer 0;
  padding: $spacer;
  width: 100%;

  .icon {
    align-self: center;
    margin-right: $spacer;
  }

  .contentWrapper {
    align-self: center;
    font-size: $smallFontSize;
    line-height: $smallLineHeight;
    padding: 0;
    width: 100%;

    .title {
      color: $primary;
      font-weight: 700;
      margin: 0 0 5px;
    }
  }
}
